.consentContent{
    width: 60%;
    margin: auto;
    border: solid 1px var(--quility-border-color);
    border-radius: var(--quility-border-radius);
    height: 500px;
    margin-top: 100px;
    overflow: auto;
    background-color: var(--quility-block-background-color);
    padding: 20px;
    position: relative;
}
.consentWrapper{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
    background-color: #80808054;
    height: 100%;
}
.callRecordingOption{
    border: solid 1px var(--quility-border-color);
    border-radius: var(--quility-border-radius);
    height: 40px;
    margin: 10px 0px;
    line-height: 40px;
    width: 90%;
    margin-left: 5%;
    cursor:pointer;
}
.selectedRecordingOption{
    background-color: var(--quility-color);
    color: var(--quility-light-text) !important;
}
.titleColor{
    color: var(--quility-light-text) !important;
}