:root {
    --quility-color:#45bcaa;
    --quility-dark-green: #005851;
    --quility-extra-dark-green:#003e3a;
    --quility-medium-dark-green:#337973;
    --quility-button-color: #44bbaa;
    --quility-dark-hover-color:#44bbaa;
    --quility-block-background-color:#ffffff;
    --quility-nested-block-background-color:#ffffff;
    --quility-block-header-background-color:#ffffff;
    --quility-nested-block-header-background-color:#f1f1f1;
    --quility-hover-grey-color:#f1f1f1;
    --quility-text-on-background:#000000;
    --quility-light-background: #ffffff;
    --quility-accent-background:#f1f1f1;
    --quility-color-button-text-color:#ffffff;
    --quility-left-side-bar-color : #005851;
    --quility-veil: #003e3a75;
    --quility-burst-color: #000000;
    --quility-light-hover-color:#daf3f0;
    --quility-border-color: #d0d0d0;
    --quility-dark-text: #000000;
    --quility-light-text: #ffffff;
    --quility-input-background-color: #f8f8f8;
    --quility-input-border-color:#a4a4a4bf;
    --background-color: #e4e4e4;
    --secondary-color: #c5d9f0;
    --quility-button-color-hover: #005751;
    --quility-button-color-destructive: #f95951;
    --quility-button-color-destructive-hover: #b20221;
    --quility-deactive-color:#a1c3be;
    --quility-header-color:#f1f1f1;
    --quility-close-window-button: #000000;
    --quility-close-button-color:#44bbaa;
    --quility-dark-grey:#707070;
    --quility-border-radius:5px;
    --quliity-light-green:#cbfbef;
}