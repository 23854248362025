.successfulFunction{
    position: fixed;
    top: -120px;
    z-index: 20;
    border: solid 1px;
    background-color: #d0d0d0;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    width: 250px;
    height: 100px;
    left: 0;
    margin-left: 50%;
    transform: translateX(-50%);
    box-shadow: 3px 3px 20px -5px black;
    border-top: none;
    border-color: #bbbbbb;
    transition:all .5s;
}
.displaySuccess{
    top:50px;
}
.successfulFunctionContent{
    height: 100%;
}
.successfulFunctionTitle{
    margin-top: 5px;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
}
.successfulIcon{
    margin-top: 10px;
    text-align: center;
    font-weight: 700;
    font-size: 24px;
    color: #005751;
}