

.imageStyle{
    width: 60%;
    margin-top: 30px;
}
.mainBackground{
    font-family: mont,sans-serif;
    height: 100%;
    overflow-y: hidden;
    background-color: var(--quility-color);
}


/*@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;700&display=swap');*/
.pageTitle{	
	margin-bottom: 20px;
    font-size: 29px;
}
.smallTitle{
    font-size: 18px;
    font-weight: 600;
}
.loginWithQuilityWrapper{
    margin: 20px;
    margin-bottom: 50px;
}
.loginWithQuility{
    width: 250px;
    margin: auto;
    height: 35px;
    line-height: 35px;
    border-radius: var(--quility-border-radius);
    background-color: var(--quility-color);
    color: var(--quility-light-text);
    cursor:pointer;
    font-weight: 600;
}
.symbol{
    display: inline-block;
    width: 30px;
    vertical-align: sub;
    margin-right: 20px;
    margin-left: -40px;
}
.loginInputTitle{
    text-align: left;
    width: 80%;
    margin: auto;
    max-width: 450px;
}
.loginWrapper{
    padding: 30px 0px;
    width: 50%;
    border: solid 1px var(--quility-border-color);
    text-align: center;
    overflow: auto;
    margin: 200px auto 0%;
    color: var(--quility-dark-text);
    background: var(--quility-block-background-color);
    border-radius: var(--quility-border-radius);
    opacity: 1;
    max-width: 600px;
    position: relative;
}
.inputBox{
    border: solid 0.5px var(--quility-border-color);
    font-size: 18px;
    margin: 5px 0px;
    width: 80%;
    font-family: inherit;
    color: var(--quility-dark-text);
    text-indent: 10px;
    height: 38px;
    background-color: var(--quility-input-background-color);
    border-radius: var(--quility-border-radius);
    max-width: 450px;
}
.loginButton{
    height: 40px;
    width: 200px;
    font-size: 20px;
    background-color: var(--quility-color);
    border: none;
    color: var(--quility-light-text);
    font-weight: 700;
}
.miniButton{
	border: solid 1px #102f66;
    height:40px;
    border-radius: 5px;
    display: inline-block;
    width: 200px;
    cursor: pointer;
    text-align: center;
    font-size: 20px;
    box-shadow: 4px 4px 15px -5px #102f66;
    background-color: #102f66;
    color: #fbfbfb;
}
.miniButton:hover{
	background-color: #44597d;	
}
.errorHolder{
	font-size: 22px;
    color: red;
    font-weight: 700;
    margin-top: -15px;
    margin-bottom: 10px;
}
.buttonWrapper{
	padding-top:10px;
}
.newPasswordContent{
	box-shadow: 0px 0px 20px 2px #031f4e;
    border: solid 1px #031f4e;
    border-radius: 5px;
    width: 700px;
    height: 400px;
    background-color: #fbfbfb;
    margin: 10% auto;
}
.passwordWrapper{
    margin-bottom: 20px;
}
.newPasswordWrapper{
	position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
	display:none;
    
}
.title{
	text-align: center;
    font-size: 22px;
    margin-top: 20px;
}
.subTitle{
	font-size:18px;
	margin: 10px;
}
.passRequirements{
	position: absolute;
    text-align: left;
    font-size: 15px;
    margin-left: 8px;
    margin-top: -8px;
}
.submitNewPassword{
	border: solid 1px;
    width: fit-content;
    margin: auto;
    padding: 8px;
    border-radius: 5px;
    margin-top: 20px;
    cursor: pointer;
    font-size: 18px;
}
.forgotPassword{
	color:inherit;
}
.backToHome{
	position:absolute;
	margin: auto;
    width: 100%;
    text-align: center;
    margin-top: -50px;
    font-size: 22px;
}
.logo{
    width: 100%;
    text-align: center;
    overflow: auto;
    height: auto;
    opacity: 1;
    padding-bottom: 30px;
}
.signInWrapper{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #80808099;
    z-index: 2;
}