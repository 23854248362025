#barWrapper{
    height: 50px;
    margin: auto;
    background-color: var(--quility-accent-background);
    display: flex;
    position: fixed;
    width: 100%;
    z-index: 3;
    border-bottom: solid 1px var(--quility-border-color);
}
.bentoBlock{
    width: 33%;
    display: inline-block;
    height: 33%;
    text-align: center;
    
}
.bentoBox{
    position: absolute;
    top: 50px;
    border: solid 1px;
    background-color: var(--quility-dark-green);
    width: 350px;
    height: 300px;
    overflow: auto;
}

.imageWrapper{
    width: 50%;
    height: 100%;
    line-height: 50px;
    text-align: right;
    flex: 3.5 1;
}
.dropDownIcon{
    color: var(--quility-dark-grey);
    margin-right: 5px;
    height: 21px;
    vertical-align: bottom;
    margin-top: 4px;
}
.topBarIconWrapper{
    position: relative;
}
.fontello{
    display: inline-block;
    vertical-align: sub;
    transform: rotate3d(180, 0, 0, 180deg);
    line-height: 15px;
}
.alertBadge{
    position: absolute;
    right: 9px;
    top: -8px;
    width: 15px;
    height: 15px;
    text-align: center;
    font-size: 11px;
    line-height: 15px;
    background-color: red;
    border-radius: var(--quility-border-radius);
    color: var(--quility-light-text);
    font-weight: 600;
}
.barMenu:hover > .hiddenOptions{
    display:block !important;
  }
  .barIcon{
    line-height: 60px;
  }
.barMenu{
    background-color: var(--quility-accent-background);
    right: 20px;
    width: 200px;
    text-align: center;
    height: 50px;
    flex: 1;
}
.mainLogo{
    height: 50%;
    margin-top: 15px;
}
.searchBar{
    flex: 2;
    text-align: center;
    line-height: 50px;
    position: relative;
}
.otherBarOptions{
    flex: 1.5;
    line-height: 50px;
    text-align: center;
}
.resultsHolder{
    z-index: 1;
    position: absolute;
    background-color: #ffffff;
    width: 80%;
    margin-left: 10%;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top: none;
    line-height: normal;
    box-shadow: 3px 3px 15px -5px;
    max-height: 300px;
    overflow: auto;
    margin-top: -8px;
}
.searchBarInput{
    width: 80%;
    height: 30px;
    border-radius: 5px;
    border: solid 1px var(--quility-input-border-color);
    background-color: var(--quility-input-background-color);
    font-size: 20px;
    text-indent: 5px;
}

.searchResult{
    text-align: left;
    font-size: 18px;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    border: solid 1px var(--quility-border-color);
    margin: 20px 5px;
    border-radius: var(--quility-border-radius);
}
.searchHeader{
    background-color:var(--quility-nested-block-header-background-color);
}
.searchName{
    display: inline-block;
    min-width: 200px;
    text-indent: 10px;
}
.searchNumber{
    display:inline-block;
    width: 20%;
    min-width:150px;
}
.resultLineTitle{
    color: var(--quility-dark-grey);
    font-size:16px;
}
.resultLine{
    width: 90%;
    margin: auto;
}
.searchResultValue{
    font-weight: 600;
    font-size: 15px;
}
.lineBlock{
    display: inline-block;
    line-height: normal;
    width: 50%;
    vertical-align: middle;
}
.searchResult:hover{
    background-color: #34609e38;
}
.topBarIcon{
    color: var(--quility-dark-grey);
    font-size: 18px;
    margin: 0px 15px;
}
.agentInfoWrapper{
    height: 100%;
    border-left: solid 1px;
    cursor:pointer;
}
.agentInfoLabel{
    width: 35px;
    height: 35px;
    border-radius: 50%;
    line-height: 35px;
    background-color: var(--quility-color);
    color: var(--quility-light-text);
    margin-top: 8px;
    font-size: 14px;
    display: inline-block;
    text-transform: capitalize;
}
.agentDetail{
    display: inline-block;
    vertical-align: middle;
    font-size: 13px;
    margin-left: 5px;
    text-align: left;
    padding-left: 5px;
    max-width: 120px;
    width: calc(60% - 10px);
}
.bentoName{
    line-height: normal;
    font-size: 12px;
}
.agentInfoName{
    color: var(--quility-color);
    font-weight: 500;
    text-transform: capitalize;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.agentInfoAgency{
    font-size: 10px;
    text-transform: capitalize;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.additionalAgentInfo{
    background-color: var(--quility-block-background-color);
    box-shadow: 3px 9px 10px -10px;
    border-bottom-left-radius: var(--quility-border-radius);
    border-bottom-right-radius: var(--quility-border-radius);
    border: solid 1px var(--quility-border-color);
    border-top: none;
    margin-left: -1px;
    margin-right: -1px;
    margin-top: -4px;
}
.additionalInfoLine{
    text-align: left;
    padding: 10px;
    font-size: 14px;
}
.additionalInfoLine:hover{
    background-color:var(--quliity-light-green);
}
.logout{
    border-top: solid 1px var(--quility-border-color);
}
.openAgent{
    border-color:var(--quility-border-color);
}
.closeAgent{
    border-color:var(--quility-accent-background);
}
.selectedOption{
    background-color:var(--quliity-light-green) !important;
    border-left:solid 6px var(--quility-dark-green) !important;
    color: var(--quility-dark-green) !important;
}
.adjustIcon{
    margin-left: -6px;
}