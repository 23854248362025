.messagePreview{
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin: 8px 0px;
    padding:3px;
    height: 60px;
    outline: solid 0.5px #69afff;
    border-radius: 5px;
}
.messagePreview:hover{
    outline:solid 1px;
    border-radius:5px;
}
.messageEdit{
    display: block;
    width: 100%;
    border-radius: 5px;
    height: 60px;
    font-family: inherit;
    font-size: inherit;
    color: inherit;
    transition: all .5s;
}
.transitionMessage{
    height: 200px;
}
.editorOption{
    height: 30px;
    display: inline-block;
    line-height: 30px;
    border-radius: 5px;
    padding: 0px 10px;
    margin: 5px;
}
.quilityAdjust{
    display: inline-block;
    background-color: var(--quility-extra-dark-green);
    color: var(--quility-light-text);
    font-weight: 700;
}
.quilityAdjustContent{
    background-color: var(--quility-extra-dark-green);
    color: var(--quility-light-text);
    z-index:33;
}