.customLeadData{
    text-align: left;
    width: 80%;
    margin: auto;  
}
.number{
    color:#000000;
    font-weight:500;
    margin-left: -20px;
    margin-right: 5px;
}
.qualifierFieldInline{
    height: 15px;
    border-radius: 5px;
    border: solid 1px var(--quility-input-border-color);
    background-color: var(--quility-input-background-color);
    padding: 5px;
    color: var(--quility-dark-text);
}
.spouseInputHolder{
    width: 60%;
    margin: auto;
}
.numberList{
    width: 90%;
    margin-left: 5%;
    display: inline-block;
}
.scriptTitle{
    text-align: center;
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 20px;
    color: #000000;
}
.scriptBlockTitle{
    color: #000000;
    font-weight: 700;
    padding:5px 0px;
}
.productHolder{
    border: solid 1px var(--quility-border-color);
    border-radius: var(--quility-border-radius);
    position: relative;
    background-color: var(--quility-block-background-color);
    margin-top: 20px;
}
.activeOptionButton{
    background-color:var(--quility-button-color-hover);
}
.scriptingWrapper{
    height: calc(92% - 110px);
    margin: 20px 10px;
    overflow: auto;
    border: solid 1px var(--quility-border-color);
    padding: 10px;
    border-radius: var(--quility-border-radius);
}
.customInfoTitle{
    font-weight: 700;
}
.scriptLineBreak{
    padding: 10px 20px;
    font-size: 14px;
}
.inscript{
    font-weight: 600;
    font-size: 13px;
    background-color: #d7fdf8;
    padding: 10px;
}
.scriptSection{
    border: solid 1px var(--quility-border-color);
    border-radius: 5px;
    margin: 15px 5px;
    background-color: #f9f9f9;
    overflow: hidden;
}
.selectedSection{
    background-color: #d7fdf8 !important; 
}
.smallScrit{
    background-color: #d7fdf8;
    padding: 5px;
    display: inline-block;
    border-radius:var(--quility-border-radius)
}
.keyValue{
    padding:3px;
}
.addSpouseButton{
    height: 22px;
    line-height: 22px;
    padding: 0px 5px;
    border-radius: var(--quility-border-radius);
    cursor: pointer;
    display: inline-block;
    margin: 0px 5px;
    width: fit-content;
    text-align: center;
    font-size: 11px;
    font-weight: 600;
}
.scriptSubTitle{
    font-size: 17px;
    font-weight: 700;
}
.qualifierAdjustment{
    margin: 15px;
    display: inline-block;
    overflow: auto;
    height: calc(92% - 110px);
}
.scriptAdjustment{
    width: calc(88% - 50px);
    display: inline-block;
    margin-left: 14%;
    padding-bottom: 0px;
    margin-bottom: 0px;
}
.sideTabs{
    width: 13%;
    position: absolute;
    height: 85%;
    line-height: 50px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}
.sideTab{
    flex: .5 1;
    font-size: 12px;
    max-height: 35px;
    display: block;
    cursor: pointer;
    text-align: left;
    background-color: #f8f8f8;
    margin: 5px 0px;
    line-height: 34px;
    text-indent: 4px;
    border-top-right-radius: var(--quility-border-radius);
    border-bottom-right-radius: var(--quility-border-radius);
}
.sideTab:hover{
    background-color: var(--quility-nested-block-header-background-color);
}
.selectedSideTab{
    background: var(--Colors-Background-bg-brand-primary, #F0FDF9) !important;
    color:var(--quility-color);
    font-weight:bold;
    
}
/*.sideTab:first-of-type {
    border-top-right-radius: var(--quility-border-radius);
    border-top:solid 1px;
}
  
.sideTab:last-of-type {
    border-bottom-right-radius: var(--quility-border-radius);
    border-bottom:solid 1px;
}*/