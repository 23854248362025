@font-face {
    font-family: 'Quility';
    src: url('../fonts/montserrat-v25-latin-300.woff2') format('woff2'),
         url('../fonts/montserrat-v25-latin-700.woff2') format('woff2'),
         url('../fonts/montserrat-v25-latin-700italic.woff2') format('woff2'),
         url('../fonts/montserrat-v25-latin-800.woff2') format('woff2'),
         url('../fonts/montserrat-v25-latin-800italic.woff2') format('woff2'),
         url('../fonts/montserrat-v25-latin-900.woff2') format('woff2'),
         url('../fonts/montserrat-v25-latin-900italic.woff2') format('woff2'),
         url('../fonts/montserrat-v25-latin-italic.woff2') format('woff2'),
         url('../fonts/montserrat-v25-latin-regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}
.seeMore{
    color: var(--quility-color);
    font-weight: 699;
    margin: 10px 0px;
    text-decoration: underline;
    cursor: pointer;

}
.textInputBox{
    border: solid 1px;
    width: 200px;
    background-color: #f1f1f1;
    border-color: #adadad;
    border-radius: 3px;
    height: 25px;
    font-family: inherit;
    position: relative;
}
.textInputTitle{
    font-family: 'Quility';
    font-size: 11px;
    color: #959595;
    font-weight: 900;
    letter-spacing: normal;
}
input:focus {
    border-color: var(--quility-veil);
    outline: none;
}
.textInputContainer{
    margin: 0px 15px;
}
.selectInputContainer{
    margin: 0px 15px;
}
.selectInputTitle{
    font-family: 'Quility';
    font-size: 11px;
    color: #959595;
    font-weight: 900;
    letter-spacing: normal;
}
.selectBoxOptionWrapper{
    border: solid 1px;
    border-color: #adadad;
    border-radius: 3px;
    font-family: 'Quility';
    margin-top: 15px;
    font-size: 12px;
    color: black;
    position: absolute;
    width: 100%;
    z-index: 1;
    background-color: #ffff;
    max-height: 200px;
    overflow: auto;
}
.selectBoxSelectedItem{
    border-color: #adadad;
    border-radius: 3px;
    font-family: 'Quility';
    font-size: 12px;
    color: black;
    line-height: 25px;
    font-weight: 700;
    display: flex;
    justify-content: space-between;
}
.selectBoxOption{
    padding: 5px;
}
.multiSelectOption{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    line-height: 35px;
    cursor:pointer;
    user-select: none;
}
.multiSelectOption:hover{
    background-color: var(--quility-light-hover-color);
}
.selectBoxOption:hover{
    background-color:var(--quility-light-hover-color);
}
.selectBoxIcon{
    transition:all .5s;
}
.selectBoxIconWrapper{
    padding: 0px 5px;
}
.selectBoxName{
    margin-left: 5px;
}
.rotateIcon{
    transform: rotate(180deg)
}
.selectInputTitleWrapper{
    margin: 5px 0px;
}
.dataInfoText{
    font-size: 14px;
}
.dataInfoLine{
    line-height: 30px;
    border-bottom: solid 2px #f1f1f1;
    width: 95%;
}
.dataInfoTitle{
    display: inline-block;
    font-weight: 700;
    font-size: 14px;
    width: 200px;
}
/***** modals ****/
.frontPageModal{
    border: solid 1px;
    height: 30%;
    border-radius: 5px;
    margin: 20px 0px;
}
/***** buttons *******/
.filterButton{
    height: 30px;
    line-height: 30px !important;
    width: calc(100% - 30px);
    border-radius: 3px;
    display: inline-block;
    margin: 5px 0px;
}
.resultButton{
    line-height: 40px;
    position: absolute;
    top: 10px;
    border: solid 1px;
    padding: 0px 10px;
    border-radius: 5px;
    left: 10px;
}
.filterButtonWrapper{
    position: relative;
    display: inline-block;
    width: 50%;
}
.filterButtonTitle{
    font-size: 14px;
    font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;
    width: 200px;
    text-indent: 10px;
}
.outlineButton:hover{
    color:var(--quility-button-color-hover);
    border-color:var(--quility-button-color-hover);
}
.solidButton:hover{
    background:var(--quility-button-color-hover) !important;
}
.removeableOption{
    border-radius: 20px;
    width: -moz-fit-content;
    width: fit-content;
    padding: 0px 10px;
    height: 25px;
    line-height: 25px;
    font-size: 14px;
    position: relative;
    display: inline-block;
    margin: 5px 5px;
    background-color: #f1f1f1;
    color: black;
    max-width: 110px;
}
.removeableRemove{
    position: absolute;
    top: -3px;
    right: -6px;
    border-radius: 50%;
    width: 13px;
    height: 13px;
    text-align: center;
    line-height: 13px;
    background-color: #f1f1f1;
    font-size: 10px;
    cursor:pointer;
}
.filterButtonIcon{
    padding: 0px 10px;
    float: right;
}
.openFilters{
    width: 100px;
    line-height: 40px;
    padding: 0px 10px;
    background-color: #47c3b2;
    border-radius: 5px;
    margin: auto;
    text-align: center;
    font-weight: 500;
    margin-top: 40px;
}
.filterButtonSelectedItem{
    font-family: 'Quility';
    font-size: 12px;
    font-weight: 800;
    display: flex;
    justify-content: space-between;
    border: solid 0.5px;
    border-radius: 3px;
    user-select: none;
    text-align: left;
}
.solidButton{
    color:var(--quility-light-text);
    background-color:var(--quility-button-color);
}
.outlineButton{
    border-color: var(--quility-input-border-color);
    background-color: var(--quility-input-background-color);
}

/*****qulity parent block *****/
.quilityParentBlockWrapper{
    display: inline-block;
    width: calc(50% - 20px);
    margin: auto;
    border-radius: 5px;
    background-color: #ffffff;
    transition: all .5s;
    overflow: hidden;
    margin: 0px 10px;
    border: solid 2px var(--quility-border-color);;
    vertical-align: top;
}
.quilityParentBlockTitle{
    text-indent: 15px;
    display: inline-block;
    color: var(--quility-dark-text);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    vertical-align: middle;
}
.quilityParentBlockHeader{
    height: 45px;
    background-color: var(--quility-nested-block-header-background-color);
    line-height: 45px;
    font-weight: 700;
    text-align: left;
}
.quilityNestedBlockHeader{
    height: 45px;
    background-color: var(--quility-nested-block-header-background-color);
    line-height: 45px;
    font-weight: 700;
    text-align: left;
}
.quilityParentBlockContent{
    text-align: center;
    padding: 10px 20px;
    height: fit-content;
    overflow: auto;
    min-height: 200px;
}
.openIcon{
    float: right;
    margin-right: 20px;
}
.quilityBlockIcon{
    float: right;
    padding: 0px 20px;
    color:var(--quility-dark-text);
}
.quilityNestedBlockContent{
    padding: 0px 20px;
    transition: all .5s;
    color: var(--quility-dark-text);
}
.dataFieldTitle{
    font-weight: 700;
    margin: 5px 0px;
    width: 60%;
    max-width:150px;
}
.badgeButton{
    background-color: #f1f1f1;
    padding: 0px 10px;
    height: 25px;
    display: inline-block;
    line-height: 25px;
    text-indent: 0px;
    border-radius: 20px;
    font-weight: 700;
    font-size: 14px;
    min-width: 70px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
    margin: 5px;
    width: fit-content;
}
.quilityNestedBlockWrapper{
    display: inline-block;
    text-align: left;
    width: calc(100% - 2px);
    border-radius: 5px;
    background-color: var(--quility-nested-block-background-color);
    transition: all .5s;
    overflow: hidden;
    border: solid 1px var(--quility-border-color);
    position: relative;
    margin: 5px auto;
}
.leadInfoWrapper{
    height: 66%;
    overflow: auto;
}
.dispositionWrapper{
    display: flex;
    flex-direction: column;
    height: 7%;
}
.leadCardContentAdjustment{
    display: flex;
    flex-direction: column;
    height: 93%;
    padding: 0px 20px;
}
.filterOptionsBarWrapper{
    display: flex;
    flex-flow: wrap;
    justify-content: space-evenly;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}
.filterOptionWrapper{
    position: fixed;
    border-radius: 3px;
    font-family: 'Quility';
    font-size: 12px;
    color: black;
    z-index: 2;
    overflow: auto;
    background-color: #ffffff;
    border: solid 0.5px #0c4465;
    width: 17%;
    max-width: 220px;
    box-shadow: 7px 7px 20px -10px #0c4465;
    max-height: 220px;
    margin-top: 5px;
    text-align: left;
    text-indent: 10px;
}
.QuilityCheckboxWrapper{
    margin: 0px 5px;
    padding-top: 0px;
    display: inline-block;
    vertical-align: sub;
}
.quilityDataLineTitle{
    font-weight: 700;
    display: inline-block;
    width: 150px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

}
.quilityDataLine:last-child{
    border-bottom:none;
    padding-bottom: 25px;
}
.quilityDataLineTitleShort{
    font-weight: 700;
    display: inline-block;
    width: 100px;
}
.infoDataBlockWrapper{
    display:flex;
}
.infoDataBlock{
    display: inline-block;
    flex: 1;
}
.quilityDataLine{
    padding: 15px 0px;
    border-bottom: solid 2px #f1f1f1;
    font-size: 14px;
    color: var(--quility-dark-text);
}
.removeableTitle{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    display: inline-block;
}
.frontPageQuilityParentBlockWrapper{
    display: inline-block;
    width: 95%;
    margin: auto;
    border-radius: 5px;
    background-color: var(--quility-block-background-color);
    transition: all .5s;
    overflow: hidden;
    margin: 0px 10px;
    border: solid 2px var(--quility-border-color);
    vertical-align: top;
    margin-bottom: 20px;
}
.smallBlock{
    width: calc(25% - 20px) !important;
}
.filterOptionButton{
    display: inline-block;
    height: 35px;
    line-height: 35px;
    width: 100%;
    border-radius: 5px;
    margin: 5px 0px;
    background-color: #0c4465;
    color: #ffffff;
}
.filterName{
    width: 75%;
    display: inline-block;
    vertical-align: top;
    font-weight: 700;
    text-indent: 10px;
    margin-bottom: 15px;
}
.filterActionButton{
    width: calc(20% - 6px);
    display: inline-block;
    vertical-align: top;
    font-size: 14px;
    background-color: #399359;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    font-weight: 700;
    border: solid 3px #5aeb8d;
    height: calc(100% - 6px);
}
.filterActionAction{
    cursor: pointer;
    text-align: center;
    line-height: 30px;
    font-size: 13px;
}
.filterActionButtonInner{
    display: flex;
    flex-direction: column;
    height: 100%;
}
.leadCount{
    font-weight: normal;
    margin-left: 10px;
    border-radius: 50px;
    padding: 2px 10px;
    font-size: 11px;
    background-color: var(--quility-block-background-color);
}
.nameFilterWrapper{
    display: inline-block;
    margin-left: 15px;
    cursor: pointer;
    line-height: 25px;
    border-radius: 10px;
    padding: 0px 10px;
    font-size: 15px;
    font-weight: 700;
    transition:all .5s;
}
.filterInputWrapper{
    margin: 5px 0px;
    display: inline-block;
    width: 170px;
}
.nameFilterTitle{
    display: inline-block;
    font-size:13px;
}
.filterOutWrapper{
    text-align: left;
    margin: 10px 14px -10px 14px;
}
.jumpIntoButton{
    display: inline-block;
    font-size: 14px;
    line-height: 25px;
    padding: 2px 5px;
    border-radius: 3px;
    margin: 0px -10px 0px 5px;
    cursor: pointer;
    margin-right: 0px;
}
.optionButtonsHolder{
    float: right;
}
.saveFilterButtonOption{
    display: inline-block;
    width: 40px;
    text-align: center;
}
.cancelSave{
    color: #c64f4f;
}
.frontMessagePreview{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    line-height: 1.2em;
    max-height: 3.6em;
    font-size: 14px;
    padding: 0px 10px;
    color: var(--quility-dark-text);
}
.convoPreviewWrap{
    border-bottom: solid 1px;
    display: inline-block;
    padding-bottom: 10px;
    border-color: var(--quility-border-color);
    cursor: pointer;
    padding: 5px 0px;
    width: 100%;
    min-height: 85px;
}
.convoPreviewWrap:hover{
    background-color: var(--quility-hover-grey-color)
}
/****url('../fonts/montserrat-v25-latin-300italic.woff2') format('woff2'),
         url('../fonts/montserrat-v25-latin-500.woff2') format('woff2'),
         url('../fonts/montserrat-v25-latin-500italic.woff2') format('woff2'),
         url('../fonts/montserrat-v25-latin-600.woff2') format('woff2'),
         url('../fonts/montserrat-v25-latin-600italic.woff2') format('woff2'),*/


