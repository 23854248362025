.formWrapper{
    width: 51%;
    height: 500px;
    margin: auto;
    padding-top: 30px;
}
.p-PaymentDetails-group{
    color: #00357f;
}
.cancelled{
    background-color: #ffe9e9;
}
.subscribeModalWrapper{
    position: fixed;
    z-index: 100;
    width: 100%;
    height: 100%;
    background-color: #5252528a;
}
.addNewCard{
    display: inline-block;
    position: absolute;
    margin-left: 19px;
    border-radius: var(--quility-border-radius);
    padding: 2px 10px;
    font-size: 14px;
    cursor:pointer;
}
.subscribeInfoWrapper{
    width: 60%;
    margin: auto;
}
.subscribeModalContent{
    background-color: var(--quility-block-background-color);
    width: 90%;
    margin: auto;
    height: 80%;
    margin-top: 5%;
    border-radius: var(--quility-border-radius);
    border: solid 2px var(--quility-border-color);
    position:relative;
    overflow: scroll;
}
.inactiveButton{
    background-color:#b4b4b4;
    cursor:not-allowed !important;
}
.subscribeButton{
    width: 200px;
    cursor: pointer;
    font-size: 15px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    border-radius: var(--quility-border-radius);
    user-select: none;
}
.subscribeLogo{
    width: 40%;
    margin-top: 30px;
    min-width: 400px;
    max-width:900px;
}
.subscriptionHolder{
    display: flex;
    justify-content: space-evenly;
}
.subscriptionOptionWrapper{
    border: solid 1px var(--quility-border-color);
    border-radius: var(--quility-border-radius);
    margin: 15px 5px;
    width: 30%;
    min-width: 400px;
}
.cardWrapper{
    display: flex;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
}
.subscriptionOptionHeader{
    padding: 0px 10px;
    border-radius: 5px;
}
.subcriptionOptionContent{
    padding:10px;
}
.subscriptionOptionPrice{
    font-weight: 700;
}
.subscriptionOptionDescriptionTitle{
    margin-top: 20px;
    text-align: center;
    font-weight: 600;
}
.subscriptionWarningTitle{
    text-align: center;
    margin-top:10px;
}
.cardModalAdjustment{
    position: fixed;
    text-align: center;
    margin: auto;
    width: 120%;
    left: 3.5%;
    top: 48px;
}