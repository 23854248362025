.tutorialWrapper{
    position: absolute;
    top: 0;
    left: 0;
    width: 80%;
    height: 80%;
    background-color: #ffffff;
    z-index: 222;
    margin: auto;
    margin-left: 10%;
    margin-top: 5%;
    border-bottom: solid 1px;
    box-shadow: 0px 0px 150px -5px;
    border-radius: 5px;
    border: solid 3px var(--quility-border-color);
    overflow: auto;
}
.tutorialBlockWrapper{
    width: calc(50% - 50px);
    border: solid 1px var(--quility-border-color);
    line-height: 40px;
    text-align: center;
    border-radius: 5px;
    -webkit-user-select: none;
    user-select: none;
    display: inline-block;
    cursor: pointer;
    margin: 10px;
    vertical-align: top;
    margin-left: 30px;
}
.tutorialBlockButtonLeft{
    display: inline-block;
    width: 50%;
    /*border-top: solid 1px var(--quility-border-color);*/
    border-top-right-radius: 5px;
    border-bottom-left-radius: var(--quility-border-radius);
    transition: all .5s;
   /* border-right: solid .5px var(--quility-border-color);*/
}
.tutorialBlockButtonRight{
    display: inline-block;
    width: 50%;
    /*border-top: solid 1px var(--quility-border-color);*/
    border-top-left-radius: 5px;
    border-bottom-right-radius: var(--quility-border-radius);
    transition: all .5s;
    /*border-left: solid .5px var(--quility-border-color);*/
}
.tutorialContainer{
    width: 70%;
    margin: auto;
    margin-top:40px;
}
.hiddenContent{

    overflow: hidden;
    transition: all .5s;
}
.tutorialBlockWrapper:hover > .hiddenContent{
    height:40px;
}
.tutorialBlockButtonLeft:hover{
    background-color: var(--quility-button-color);
    color: var(--quility-light-text);
}
.tutorialBlockButtonRight:hover{
    background-color: var(--quility-button-color);
    color: var(--quility-light-text);
}
.toolTipTutorial{
    position: absolute;
    margin-left: -30px;
    border: solid 1px var(--quility-border-color);
    color: var(--quility-border-color);
    width: 20px;
    height: 20px;
    line-height: 20px;
    border-radius: 50%;
    margin-top: 10px;
}
.toolTipTutorialTip{
    position: absolute;
    width: 150px;
    border: solid 1px var(--quility-border-color);
    border-radius: 5px;
    margin-left: -95px;
    margin-top: 33px;
    background-color: #eeeeee;
    z-index: 2;
    font-size: 14px;
    line-height: normal;
    text-align: left;
    display: none;
    padding: 5px;
}
.toolTipTutorial:hover ~ .toolTipTutorialTip{
    display: block;
}
.goBack{
    position: absolute;
    top: 5px;
    left: 10px;
    border: solid 1px var(--quility-border-color);
    padding: 5px 15px;
    border-radius: 5px;
    background-color: var(--quility-button-color);
    font-weight: 700;
    cursor:pointer;
}
.componentWrapper{
    width: calc(100% - 40px);
    position: absolute;
    left: 0;
    height: 80%;
    overflow: scroll;
}