.leftHandBar{
    width: 50px;
    height: calc(100% - 50px);
    background-color: var(--quility-color);
    display: block;
    position: fixed;
    transition: all .5s;
    z-index: 2;
    margin-top: 50px;
    /*border-right: solid 2px var(--quility-border-color);*/
}
.leftBarIconHolder{
    width: 50px;
    display: inline-block;
    text-align: center;
    font-size: 16px;
}
.leftHandBar:hover{
    width:200px;
}
.additionalItemIcon{
    float: right;
    margin-right: 10px;
    transition: all .5s;
}
.searchInner{
    margin: auto;
    width: 50%;
    margin-top: 200px;
    text-align: left;
    background-color: var(--quility-block-background-color);
    box-shadow: 3px 3px 50px -5px;
    border-radius: var(--quility-border-radius);
    position: relative;
    height: fit-content;
    max-height:500px;
}
.searchModal{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #80808059;
}
.searchTitle{
    text-align: center;
    font-size: 22px;
    font-weight: 600;
}
.searchModalBar{
    border-color: var(--quility-input-border-color);
    background-color: var(--quility-input-background-color);
    border: solid 1px;
    width: 80%;
    margin-left: 5%;
    height: 35px;
    border-radius: var(--quility-border-radius);
}
.searchModalSearchButton{
    display: inline-block;
    height: 40px;
    vertical-align: middle;
    border-radius: var(--quility-border-radius);
    line-height: 42px;
    background-color: var(--quility-color);
    cursor: pointer;
    margin-left: 1%;
    width: 40px;
    text-align: center;
    color: var(--quility-light-text);
}
.searchResults{
    padding: 10px;
    max-height: 300px;
    overflow: auto;
}