.importOptionWrapper{
    margin-left: 35px;
    margin-top: 15px;
    margin-bottom: 15px;
}
.importOptionTitle{
    display: block;
    margin: 5px 0px;
}
.selectedFileText{
    margin: 10px 0px;
}
.pastImportWrapper{
    width: 85%;
    max-width: 300px;
    border-radius: 5px;
    margin: 10px 30px;
    line-height: 30px;
    text-indent: 10px;
    position: relative;
}
.pastImportWrapper:hover{
    outline: solid 2px var(--quility-border-color);
}
.importDate{
    width: 120px;
    display: inline-block;
}
.deleteImport{
    cursor: pointer;
    position: absolute;
}
.importCount{
    display: inline-block;
    width: 170px;
}
.matchSide{
    width: 50%;
    display: inline-block;
    vertical-align: top;
}