.qualifierBlock{
    outline: solid 1px;
    height: -moz-fit-content;
    height: fit-content;
    margin: 4px;
}
.productTabs{
    display: flex;
    margin-bottom: 20px;
    justify-content: center;
}
.selectedRec{
    background-color: #45bcaa2b !important;
}
.faceHolder{
    font-size: 15px;
    font-weight: 700;
    margin-top: 15px;
    margin-left: 20px;
    display: inline-block;
}
.productTab{
    width: 200px;
    text-align: center;
    cursor:pointer;
}
.conditionBubble{
    cursor: pointer;
    display: inline-block;
    position: relative;
    font-size: 12px;
    height: 25px;
    line-height: 25px;
    margin: 5px;
    padding: 0 10px;
    -webkit-user-select: none;
    user-select: none;
    width: -moz-fit-content;
    width: -webkit-fit-content;
    width: fit-content;
    background-color: #f1fdf9;
    border: .5px solid var(--quility-color);
    border-radius: 50px;
    color: var(--quility-color);
    font-weight: 600;
}
.recNameHolder{
    white-space: nowrap;
    overflow: hidden;
    width: 30%;
    font-size: 12px;
    text-overflow: ellipsis;
    text-align: left;
}
.selectedQuestion{
    background-color: var(--quility-color) !important;
    color: #ffffff;
    border-left: solid 7px var(--quility-dark-green);
    text-indent: 5px;
}
.qualifierSection{
    padding: 10px;
    border-radius: 5px;
}
.qualifierFieldWrapper{
    display: inline-block;
    width: 50%;
    border-bottom: solid 1px #f1f1f1;
    padding: 10px 0px;
}
.qualifierFieldTitle{
    font-size: 13px;
    width: 150px;
    margin-bottom: 5px;
}
.qualifierFieldQuestionTitle{
    max-width: unset;
    width: 100%;
    font-weight: 700;
    display: inline-block;
}
.qualifierFieldInput{
    height: 20px;
    border-radius: 5px;
    border: solid 1px var(--quility-input-border-color);
    background-color: var(--quility-input-background-color);
    padding: 5px;
    width: calc(100% - 20px);
    color: var(--quility-dark-text);
}
.conditionQuestionContent{
    background-color: #ffffff;
    max-width: 550px;
    width: 50%;
    margin: auto;
    margin-top: 10%;
    height: -moz-fit-content;
    height: fit-content;
    max-height: 400px;
    padding: 20px;
    border: solid 1px #b7b7b7;
    border-radius: 5px;
}
.conditionQuestionWrapper{
    position: fixed;
    z-index: 12;
    background-color: #567cb13d;
    top: 0;
    width: 100%;
    left: 0;
    height: 100%;
}
.qualDropdownItem{
    padding:3px;
    cursor:pointer;
}
.qualDropdownItem:hover{
    background-color: var(--quility-input-background-color);
}
.qualDropDownMenu{
    height: 130px;
    border: solid 1px #d5d4d4;
    overflow: auto;
    width: 100%;
    text-align: left;
    padding: 5px;
    box-shadow: 2px 6px 20px -16px;
    margin: auto;
    max-width: 235px;
}
.addTo{
    border: solid 1px #d5d4d4;
    width: -moz-fit-content;
    width: fit-content;
    padding: 0px 10px;
    border-radius: 20px;
    line-height: 25px;
    cursor: pointer;
    background-color: var(--quility-input-background-color);
    user-select: none;
    display: inline-block;
    margin: 5px;
    height: 25px;
    font-size: 14px;
}
.medSearchIcon{
    position: absolute;
    margin-left: -30px;
    margin-top: 1px;
    background-color: #c5d9f0;
    line-height: 30px;
    width: 30px;
    text-align: center;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    cursor:pointer;
    height: 30px;
}
.conditionHolder{
    margin-bottom: 10px;
    text-align: left;
}
.smallRemove{
    background-color: var(--quility-input-background-color);
    border: 1px solid var(--quility-close-button-color);
    border-radius: 50%;
    height: 15px;
    line-height: 13px;
    margin-left: 0;
    margin-top: -8px;
    position: absolute;
    text-align: center;
    width: 15px;
}
.medicationInfoContent{
    width: 530px;
    height: 300px;
    background-color: #ffffff;
    border: solid 1px #b7b7b7;
    border-radius: 5px;
    margin: auto;
    margin-top: 100px;
    padding: 70px;
    position: relative;
}
.medInfoInner{
    display: inline-block;
    width: 100%;
    height: 100%;
    overflow: auto;
    border: solid 1px #b7b7b7;
    padding: 5px;
    border-radius: 5px;
}
.medicationInfoWrapper{
    position: fixed;
    top: 0;
    left: 0;
    background-color: #567cb138;
    width: 100%;
    height: 100%;
    z-index: 3;
}
.medInfo{
    position: absolute;
    background-color: var(--quility-input-background-color);
    border: solid 1px #b7b7b7;
    width: 15px;
    text-align: center;
    height: 15px;
    line-height: 13px;
    border-radius: 50%;
    margin-left: -28px;
    margin-top: 5px;
}
.recLogo{
    width:85px;
}
.recLogoHolder{
    width: 20%;
    text-align: center;
}
.recTitle{
    width: 55%;
    font-size: 14px;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.recLink{
    width: -moz-fit-content;
    width: fit-content;
    height: 30px;
    line-height: 30px;
    padding: 0px 20px;
    border-radius: var(--quility-border-radius);
    cursor: pointer;
    display: inline-block;
    margin: 0px 5px;
    font-size: 12px;
    width: 20%;
    text-align: center;
    max-width: 75px;
    font-weight: 700;
}
.recQuote{
    width:25%;
    font-size: 18px;
    font-weight:500;
    text-align: center;
}
.recTop{
    display: flex;
    flex-direction: row;
    height: 60px;
    line-height: 60px;
}
.recBottom{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.recBlock{
    border: solid 1px var(--quility-border-color);
    width: 90%;
    height: 100px;
    margin: 20px auto;
    border-radius: 5px;
    padding: 5px;
    cursor:pointer;
}
.recName{
    font-size:20px;
    font-weight: 700;
    line-height: normal;
}

.recOptions{
    display: flex;
    justify-content: space-around;
    padding-bottom: 10px;
    width: 96%;
    margin-left: 2%;
}
.recInputTitle{
    font-size: 12px;
    color: var(--quility-dark-grey);
    margin: 3px 0px;
    text-align: left;
    text-indent: 5%;
}
.recResults{
    height: calc(100% - 90px);
    overflow: auto;
}
.recInputHolder{
    width: 33%;
    text-align: center;
}
.recInput{
    height: 27px;
    border-radius: var(--quility-border-radius);
    border: solid 1px var(--quility-border-color);
    background-color: var(--quility-input-background-color);
    width: 90%;
}
.extraPopup{
    position: fixed;
    top: 0px;
    border: solid 1px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: #8080805c;
    z-index: 2;
}
.recInputSelect {
    height: 30px;
    border-radius: var(--quility-border-radius);
    border: solid 1px var(--quility-border-color);
    background-color: var(--quility-input-background-color);
    font-family: inherit;
    color: inherit;
    width: 90%;
}
.extraPopupContent{
    border: solid 1px var(--quility-border-color);
    margin: auto;
    width: 450px;
    background-color: var(--quility-block-background-color);
    height: fit-content;
    margin-top: 100px;
    border-radius: var(--quility-border-radius);
    padding: 5px;
    position: relative;
    max-height: 500px;
}
.recDescription{
    font-size: 12px;
    margin: 0px 15px;
    color: var(--quility-dark-grey);
    overflow: auto;
    display: inline-block;
    height: 390px;
    overflow: auto;
}
.recBubble{
    border-radius: 50px;
    border: solid .5px var(--quility-color);
    width: -moz-fit-content;
    width: fit-content;
    padding: 0px 5px;
    color: var(--quility-color);
    font-weight: 600;
    font-size: 10px;
    height: 20px;
    line-height: 20px;
    background-color: #f1fdf9;
    margin: 0px 5px;
}
.optionBubbles{
    display: flex;
    margin: 10px 0px;
    justify-content: flex-start;
}
.bottomLinks{
    margin-bottom: 10px;
    display: flex;
}
.extraPopupTitle{
    font-size: 14px;
    font-weight: 700;
    padding: 10px;
}
.recBottomLink{
    color: var(--quility-color);
    font-weight: 700;
    font-size: 12px;
    border-bottom: solid 1px;
    width: fit-content;
    margin: 0px 10px;
}