.leaderBoardWrapper{
    width: 0px;
    height: calc(100% - 50px);
    background-color: var(--quility-block-background-color);
    box-shadow: -4px 18px 31px -19px var(--quility-burst-color);
    position: fixed;
    z-index: 4;
    overflow: hidden;
    transition:all .5s;
    right: 0;
    margin-top: 50px;
}
.openedLeaderBoard{
    width:400px;
}
.leaderWrapper{
    height: 50px;
    line-height: 50px;
    background-color: var(--quility-nested-block-background-color);
    width: 90%;
    border-radius: 5px;
    margin: 20px auto;
    padding: 0px 5px;
}
.leaderAvatar{
    display: inline-block;
    flex: .5;
}
.leaderMedal{
    flex: .25;
    display: inline-block;
}
.leaderName{
    flex: 2 1;
    font-size: 16px;
    display: inline-block;
    font-weight: 600;
    text-indent: 5px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color:var(--quility-dark-text);
}
.leaderCount{
    flex: .5 1;
    display: inline-block;
    text-align: right;
    margin-right: 5px;
    font-weight: 700;
}
.leaderBoardContent{
    overflow: auto;
    height: calc(100% - 100px);
}
.leaderBoardTitle{
    font-size: 26px;
    text-align: center;
    color: var(--quility-dark-text);
    font-weight: 700;
    margin: 15px;
    height: 30px;
}
.leaderType{
    border: solid 1px var(--quility-color);
    width: 60px;
    display: inline-block;
    flex: 1 1;
    margin: 0px 10px;
    border-radius: 5px;
    text-align: center;
    height: 30px;
    line-height: 30px;
    margin-bottom: 20px;
    cursor: pointer;
    background-color: var(--quility-color);
    color: var(--quility-light-text);
    font-weight: 700;
    font-size:14px;
}
.dateWrapper{
    text-align: center;
    color: var(--quility-dark-text);
    font-weight: 700;
}
.innerSmallLoadingWrapper{
    width: 150px;
    margin: auto;
    margin-top: 50%;
}
.smallLoadingWrapper{
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    background-color:  var(--quility-veil);
    text-align: center;
}
.leaderTypeWrapper{
    display: flex;
    height: 50px;
}
.activeButton{
    background-color: var(--quility-extra-dark-green);
    color: var(--quility-light-text);
    border-color: var(--quility-extra-dark-green);
}
.innerLeaderMedal{
    display: inline-block;
    width: 30px;
    border-radius: 50%;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    color:var(--quility-dark-text);
}
.place0{
    color: #ba9d00;
}
.place1{
    color:#808080;
}
.place2{
    color: #8f3030;
}
.innerLeaderWrapper{
    display: flex;
}
.innerLeaderAvatar{
    display: inline-block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color:  var(--quility-block-background-color);
    text-align: center;
    line-height: 40px;
    border: solid 0.5px  var(--quility-border-color);
    font-size: 18px;
    font-weight: 700;
    margin-top: 5px;
    color: var(--quility-dark-text);
}
.largeLeaderWrapper{
    display:none;
}
.display{
    display:block;
}
.boardTitle{
    text-align: center;
    font-size: 18px;
    color: var(--quility-dark-text);
    font-weight: 800;
}
.headerNames{
    font-weight: 700;
    display: flex;
    width: 90%;
    margin: auto;
    margin-bottom: -10px;
    margin-top: 15px;
    background-color: var(--quility-dark-green);
    color: var(--quility-light-text);
    padding: 5px;
    border-radius: 5px;
    font-size: 14px;
}
.headerName{
    flex:1
}
.name3{
    text-align: right;
}