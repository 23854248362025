.taggingWrapper{
    position: relative;
    width: 265px;
    float: right;
    right: -220px;
    display: flex;
    transition: all 1s;
    margin-top: 5px;
    line-height: normal;
}
.tagInput{
    height: 20px;
    border-radius: 5px;
    border: solid 1px var(--quility-input-border-color);
    background-color: var(--quility-input-background-color);
    padding: 5px;
    max-width: 200px;
    width: 100%;
    color: var(--quility-dark-text);
}
.tagIcon{
    padding: 0px 15px;
    line-height: 35px;
    font-size: 20px;
}
.tagInputWrapper{
    width: 220px;
    overflow: hidden;
    transition:all .5s;
}
.suggestionList{
    position: absolute;
    border: solid 1px var(--quility-border-color);
    width: 100%;
    z-index: 2;
    background-color: var(---quility-block-header-background-color);
    border-radius: 5px;
}
.tagWrapper{
    border: solid 1px var(--quility-border-color);
    margin: 0px 3px;
    border-radius: 3px;
    background-color: #ffffff;
    padding-left: 2px;
    display: inline-block;
    line-height: normal;
    vertical-align: super;
}
.removeTag{
    border-left: solid 1px;
    padding: 0px 4px;
    text-align: center;
    margin-left: 2px;
    display: inline-block;
    height: 100%;
    cursor:pointer;
}
.suggestionItem{
    padding: 5px;
    cursor: pointer;
    user-select: none;
}
.suggestionItem:hover{
    background-color: var(--quility-hover-grey-color);
}
.taggingWrapper:hover {
    right: 0px;
}