
.scheduledDate{
    flex:1;
}
.schedulefunctionButtons{
    flex: 1 1;
    text-align: center;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.scheduledName{
    flex: 1;
    text-indent: 10px;
}
.scheduledLastContacted{
    flex: 1;
}
.dateGroup{
    border-radius: 10px;
    width: 96%;
    margin: 5px auto 30px auto;
    padding: 1px 0px;
}
.dateGroupTitle{
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    padding: 10px 0px 0px 0px;
}