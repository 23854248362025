.landingWrapper{
    background-color: #ffffff;
}
.migrationTitle{
    color: var(--Colors-Foreground-fg-brand-primary, #005851);
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    /* Display lg/Bold */
    font-family: Montserrat;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height:  60px;
    letter-spacing: -0.96px;
}
.mainImage{
    width: 70%;
    border: solid 3px var(--quility-border-color);
    border-radius: 10px;
    box-shadow: 6px 14px 100px -50px;
}
.trade{
    font-size: 15px;
    line-height: 20px;
    vertical-align: top;
}
.spiel{
    color: var(--colors-foreground-fg-primary-900, #2F2F2F);
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    /* Display sm/Bold */
    font-family: Montserrat;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%; /* 40px */
}
.subSpiel{
    color: var(--colors-foreground-fg-secondary-700, #707070);
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    /* Text xl/Regular */
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%; /* 25px */
}
.subTrade{
    font-size: 13px;
    line-height: 20px;
    vertical-align: top;
}
.migrationLogo{
    display: flex;
    width: 410px;
    height: 34px;
    justify-content: center;
    align-items: center;
    margin: auto;
    gap: 12px;
}
.descriptionBlock{
    display: flex;
    min-width: 320px;
    padding-top: 30px;
    flex-direction: column;
    align-items: center;
    gap: var(--spacing-4xl, 32px);
    flex: 1 0 0;
}
.descriptionIconWrapper{
    display: flex;
    padding: 12px;
    flex-direction: column;
    align-items: flex-start;    
    position: absolute;
    top: -30px;
    border-radius: var(--radius-sm, 6px);
    border: 1px solid var(--Colors-Border-border-secondary, #E4E4E4);
    background: var(--Colors-Base-white, #FFF);
    box-shadow: 0px 2px 4px 0px var(--Colors-Effects-Shadows-shadow-xs, rgba(16, 24, 40, 0.05));
}
.descriptionBlockText{
    color: var(--colors-foreground-fg-primary-900, #2F2F2F);
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    /* Text md/Medium */
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%; /* 20px */
}
.innerDescription{
    display: flex;
    padding: 52px var(--spacing-3xl, 24px) var(--spacing-4xl, 32px) var(--spacing-3xl, 24px);
    flex-direction: column;
    align-items: center;
    gap: var(--spacing-4xl, 32px);
    align-self: stretch;
    position: relative;
    border-radius: var(--spacing-xl, 16px);
    background: var(--Colors-Background-bg-secondary, #F8F8F8);
}
.bottomWrapper{
    padding-top:20px !important;
    display: flex;
    padding: var(--spacing-9xl, 96px) 0px;
    flex-direction: column;
    align-items: center;
    gap: var(--spacing-7xl, 40px);
    align-self: stretch;
    width:75%;
    margin: auto;
}
.descriptionBoxWrapper{
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    gap: var(--spacing-7xl, 64px) var(--spacing-4xl, 32px);
    align-self: stretch;
    flex-wrap: wrap;
}
.logoSpacer{
    display: flex;
    padding: var(--spacing-9xl, 70px) 0px;
    flex-direction: column;
    align-items: center;
    gap: var(--spacing-7xl, 64px);
    align-self: stretch;
}
.landingButton{
    display: flex;
    min-height: 35px;
    line-height: 35px;
    padding: 5px 13px;
    justify-content: center;
    align-items: flex-end;
    gap: 8px;
    color: #ffffff;
    font-weight: 700;
    border-radius: var(--radius-sm, 6px);
    background-color: var(--Colors-Background-bg-brand-solid, #46C3B2);
    width: 90px;
    cursor:pointer;
    margin: 40px auto;
}
.ineligibilityScreen{
    backdrop-filter: blur(2px);
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #50505080;
    z-index: 1;
}
.landingModal{
    position:relative;
    display: flex;
    max-width: 600px;
    padding: var(--spacing-4xl, 32px) var(--spacing-5xl, 40px);
    flex-direction: column;
    align-items: center;
    gap: var(--spacing-4xl, 32px);
    border-radius: var(--radius-xl, 12px);
    background: var(--Colors-Background-bg-primary, #FFF);
    box-shadow: 0px 20px 24px -4px var(--Colors-Effects-Shadows-shadow-xl_01, rgba(16, 24, 40, 0.08)), 0px 8px 8px -4px var(--Colors-Effects-Shadows-shadow-xl_02, rgba(16, 24, 40, 0.03));
    width: 70%;
    margin: auto;
    margin-top: 15%;
}
.modalMainText{
    color: var(--colors-foreground-fg-primary-900, #2F2F2F);
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    /* Text lg/Semibold */
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%; /* 22.5px */
}
.modalSmallText{
    color: var(--colors-foreground-fg-secondary-700, #707070);
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    /* Text sm/Medium */
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 21px */
}
.descriptionModalIcon{
    display: flex;
    width: 48px;
    height: 48px;
    padding: 12px;
    justify-content: center;
    align-items: center;
    border-radius: var(--radius-full, 9999px);
    background: var(--Colors-Background-bg-brand-secondary, #CCFBEF);
}
.closeModalIconWrapper{
    display: flex;
    width: 36px;
    height: 36px;
    padding: var(--spacing-md, 8px);
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0px;
    top: 0;
    cursor:pointer;
}
.modalActionButtonWrapper{
    display: flex;
    height: 40px;
    justify-content: center;
    align-items: center;
    gap: var(--spacing-lg, 12px);
    align-self: stretch;
}
.modalActionButton{
    display: flex;
    min-height: 20px;
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 4.5px;
    background: var(--Colors-Background-bg-brand-solid, #46C3B2);
    color: #ffffff;
    font-weight: 700;
    cursor:pointer;
}
.activeBottomWrapper{
    display: flex;
    max-width: var(--container-max-width-desktop, 1280px);
    padding: 0px var(--container-padding-desktop, 32px);
    justify-content: center;
    align-items: center;
    align-content: center;
    gap: 64px var(--spacing-7xl, 0px);
    align-self: stretch;
    margin: auto;   
}
.activeBottomBlock{
    flex: 1;
    max-width: var(--container-max-width-desktop, 1280px);
    padding: 0px var(--container-padding-desktop, 32px);
    align-content: center;
    align-self: stretch;
}
.activeBlockInnerContent{
    display: flex;
    min-width: var(--width-sm, 480px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-5xl, 40px);
    width: 100%;
    position: relative;
}
.blockInnerContentTitle{
    color: var(--colors-foreground-fg-primary-900, #2F2F2F);
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    /* Display sm/Bold */
    font-family: Montserrat;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%; /* 40px */
}
.checkWrapper{
    display: flex;
    min-width: var(--width-sm, 480px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-5xl, 40px);
    flex: 1 0 0;
}
.actualCheck{
    display: flex;
    align-items: center;
    gap: var(--spacing-xs, 4px);
    align-self: stretch;
    
}
.theCheck{
    width: 28px;
    height: 28px;
    fill: var(--Colors-Background-bg-disabled, #E4E4E4);
    stroke-width: 2px;
    stroke: var(--colors-foreground-fg-senary-300, #D0D0D0);
}
.theCheck2{
    width: 28px;
    border-radius: 5px;
    height: 28px;
    background-color: var(--Colors-Background-bg-disabled, #46C3B2);
    stroke-width: 2px;
    stroke: var(--colors-foreground-fg-senary-300, #FFFFFF);
    
}
.checkText{
    color: var(--colors-foreground-fg-primary-900, #2F2F2F);
    leading-trim: both;
    text-edge: cap;
    font-feature-settings: 'liga' off, 'clig' off;
    /* Mont Reg 16 */
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.085px;
    font-size: 15px;
    margin-left: 5px;
}
.linkStyle{
    color: var(--Colors-Foreground-fg-brand-secondary, #46C3B2);
    leading-trim: both;
    text-edge: cap;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.085px;
    text-decoration-line: underline;
}
.largeBottomIconWrapper{
    display: flex;
    width: 110px;
    height: 110px;
    padding: 28px 27.999px 28px 28.001px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 1000px;
    background: var(--quility-brand-colors-water-green-rgb-100, #46C3B2);
    z-index: 1;
    margin-left: 90px;
    margin-top: 15px;
    position: absolute;
}
.largeBottomIcon{
    width: 144px;
    height: 144px;
    flex-shrink: 0;
}
.graphicLine{
    background: var(--Colors-Border-border-secondary, #E4E4E4);
    width: 576px;
    height: 1px;
}
.graphicLineWrapper{
    display: flex;
    max-width: var(--container-max-width-desktop, 1280px);
    padding: 0px var(--container-padding-desktop, 0pxpx);
    justify-content: center;
    align-items: center;
    align-content: center;
    gap: 30px var(--spacing-7xl, 36px);
    align-self: stretch;
    flex-wrap: wrap;
    position: absolute;
    width: 100%;
    margin-top: -140px;
}
.graphicLine2{
    background: var(--Colors-Border-border-secondary, #E4E4E4);
    width: 1px;
    height: 280px;
}
.graphicLineWrapper2{
    display: flex;
    max-width: var(--container-max-width-desktop, 1280px);
    padding: 0px var(--container-padding-desktop, 0pxpx);
    justify-content: center;
    align-items: center;
    align-content: center;
    gap: 34px var(--spacing-7xl, 31px);
    align-self: stretch;
    flex-wrap: nowrap;
    position: absolute;
    height: 100%;
    flex-direction: row;
}
.yellowSquare{
    width: 32px;
    height: 32px;
    transform: rotate(-90deg);
    flex-shrink: 0;
    background: var(--quility-brand-colors-yellow-rgb-100, #FC0);
    right: 175px;
    position: absolute;
    margin-top: -93px;
}
.yellowSquare2{
    width: 32px;
    height: 32px;
    transform: rotate(-90deg);
    flex-shrink: 0;
    background: var(--quility-brand-colors-yellow-rgb-100, #FC0);
    left: 175px;
    position: absolute;
    bottom: -95px;
}
.redSquare{
    width: 32px;
    height: 32px;
    transform: rotate(-90deg);
    flex-shrink: 0;
    background: var(--quility-brand-colors-red-rgb-100, #FF5851);
    position: absolute;
    right: 50px;
    bottom: -125px;
}
.blueSquare{
    width: 32px;
    height: 32px;
    transform: rotate(-90deg);
    flex-shrink: 0;
    background: var(--quility-brand-colors-blue-rgb, #0083EB);
    position: absolute;
    left: 50px;
    top: -125px;
}
.veryBottomTextWrapper{
    display: flex;
    padding: var(--spacing-9xl, 96px) 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--spacing-7xl, 64px);
    align-self: stretch;
}
.veryBottomText{
    max-width: 1440px;
    align-self: stretch;
    color: var(--colors-foreground-fg-primary-900, #2F2F2F);
    text-align: center;
    font-family: Montserrat;
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: 21px;
    margin: auto;
}
.thankyouWrapper{
    display: flex;
    padding: var(--spacing-9xl, 96px) 0px;
    flex-direction: column;
    align-items: center;
    gap: var(--spacing-7xl, 64px);
    align-self: stretch;
}
.thankyouIconWrapper{
    display: flex;
    padding: 12px;
    flex-direction: column;
    align-items: flex-start;
    width:60px;
    height:60px;
    border-radius: var(--radius-sm, 6px);
    background-color: var(--Colors-Background-bg-brand-solid, #46C3B2);
    box-shadow: 0px 2px 4px 0px var(--Colors-Effects-Shadows-shadow-xs, rgba(16, 24, 40, 0.05));
}
.thankyouIconIcon{
    width: 36px;
    height: 36px;
}
.thankyouText{
    color: var(--Colors-Foreground-fg-brand-primary, #005851);
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    /* Display lg/Bold */
    font-family: Montserrat;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%; /* 60px */
    letter-spacing: -0.96px;
}
.thankyouTextWrapper{
    display: flex;
    max-width: var(--width-xl, 768px);
    flex-direction: column;
    align-items: center;
    gap: var(--spacing-4xl, 32px);
    align-self: stretch;
    margin: auto;
}
.thankyouMessage{
    align-self: stretch;
    color: var(--colors-foreground-fg-secondary-700, #707070);
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    /* Text xl/Regular */
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%; /* 25px */
}
.migrateSpacer{
    margin-bottom: 35px;
}
.largeBottomIconWrapper2{
    display: flex;
    width: 110px;
    height: 110px;
    padding: 28px 27.999px 28px 28.001px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 1000px;
    background: var(--quility-brand-colors-water-green-rgb-100, #46C3B2);
    z-index: 1;
    top: -180px;
    right: 15px;
    position: absolute;
}
.migrationButtonWrapper{
    display: flex;
    min-width: var(--width-sm, 480px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-5xl, 40px);
    flex: 1 0 0;
}
.actualMigrationButtonDis{
    display: flex;
    /* min-height: 30px; */
    padding: 14px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 8px;
    border-radius: var(--radius-sm, 6px);
    background: var(--Colors-Background-bg-disabled, #E4E4E4);
    height: 15px;
    color:#ffffff;
    font-weight: 700;
    cursor:pointer;
}
.actualMigrationButton{
    display: flex;
    /* min-height: 30px; */
    padding: 14px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 8px;
    border-radius: var(--radius-sm, 6px);
    background: var(--Colors-Background-bg-brand-solid, #46C3B2);
    height: 15px;
    color:#ffffff;
    font-weight: 700;
    cursor:pointer;
}
.migrateButtonText{
    display: flex;
    min-height: 40px;
    padding: 14px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 8px;
}