.calendar2{
    display: flex;
    flex-direction: column;
    margin-left: -12%;
    position: fixed;
    background-color: #ffff;
    width: 80%;
    border-radius: var(--quility-border-radius);
    box-shadow: 0px 0px 450px 0px;
    overflow: scroll;
    top: 60px;
    color: black;
    height: calc(100% - 120px);
}
.calendarHeader{
    position: fixed;
    width: 80%;
    z-index: 2;
    background-color: #ffffff;
    border-top-right-radius: var(--quility-border-radius);
    border-top-left-radius: var(--quility-border-radius);
}
.time-slots {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-right: 1px solid #ccc;
}
.calendarButton{
    background-color: var(--quility-dark-green);
    border: none;
    height: 30px;
    color: var(--quility-light-text);
    font-weight: 700;
    border-radius: var(--quility-border-radius);
    width:150px
}
.week-timeline {
    display: flex;
    flex: 1;
    margin-top: 60px;
}

.day-column {
    flex: 1;
    border-left: 1px solid #ccc;
    position: relative;
    display: flex;
    flex-direction: column;
}
.calendarSelect{
    height: 30px;
    margin: 15px;
    width: 300px;
    border: solid 1px var(--quility-border-color);
    border-radius: var(--quility-border-radius);
    background-color: var(--quility-input-background-color);
    font-family: inherit;
}
.day-header {
    text-align: center;
    padding: 10px;
    font-weight: bold;
    background-color: #f1f1f1;
    font-size: 13px;
}

.day-events {
    position: relative;
    height: calc(100vh - 40px);
    overflow: hidden;
}

.time-slot {
    height: calc(100vh / 48);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #666;
    cursor: pointer;
    border-bottom: 1px solid #ddd;
}

.time-slot:hover {
    background-color: #f0f0f0;
}

.event {
    position: absolute;
    left: 5%;
    width: 90%;
    background-color: var(--quility-color);
    border: 1px solid var(--quility-border-color);
    border-radius: 4px;
    padding: 2px 4px;
    box-sizing: border-box;
    overflow: hidden;
    font-size: 12px;
    color: var(--quility-light-text);
}

.event-title {
    font-weight: bold;
}

.event-time {
    font-size: 10px;
    color: #666;
}