.modalTopBar{
    width: 45%;
    max-width: 600px;
    background-color: #e4effc;
    border: solid 1px #c5d9f0;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    margin: 20px;
    min-height: 55px;
    margin-top: 50px;
    color: #02357e;
    -webkit-user-select: none;
    user-select: none;
    display: inline-block;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    transition: all .5s;
}
.deleteList{
    display:none;
    margin-right: 5px;
    margin-top: 15px;
}
.deleteableListWrapper:hover > .deleteList{
    display:inline-block;
}
.deleteableListWrapper{
    display: flex;
}
.recentContact{
    color: #bbbbbb !important;
}
.quilityListWrapper{
    border: solid 1px var(--quility-border-color);
    border-radius: var(--quility-border-radius);
    width: calc(40% - 5px);
    padding: 10px;
    display: inline-block;
    margin: 10px 0px;
}
.selectedIcon{
    background-color:var(--quility-color) !important;
    color:var(--quility-light-text) !important;
}
.listButtonHolder{
    display: flex;
    justify-content: flex-start;
}
.filterAdjust{
    margin-left:7px;
}
.listIcon{
    transform: rotateZ(-90deg);
    border: solid 1px;
    width: 30px;
    border-radius: 50%;
    height: 30px;
    margin: 0px 3px;
    color:var(--quility-color);
    cursor: pointer;
}
.quilityOtherListWrapper{
    width: 100%;
    border: solid 1px var(--quility-border-color);
    border-radius: var(--quility-border-radius);
    padding: 10px 0px;
    margin:10px 0px;
}
.quilityOtherListTitle{
    text-align: left;
    color: var(--quility-dark-grey);
    display: inline-block;
    line-height: 33px;
    text-indent: 6px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.taskListsWrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.quilityListTitle{
    text-align: left;
    font-size: 22px;
    font-weight: 700;
    color: var(--quility-dark-green);
}
.quilityListType{
    text-align: left;
    font-size: 11px;
    color: var(--quility-dark-grey);
}
.modalIcon{
    position: absolute;
}
.modalTitle{
    position:relative;
    height: 55px;
    text-align: center;
    font-size: 22px;
    line-height: 55px;
    font-weight: 700;
}
.modalContentWrapper{
    background-color: #ffffff;
    min-height: 250px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    max-height: 250px;
    overflow: auto;
}
.modalIcon{
    position: absolute;
    line-height: 55px;
    padding-left: 15px;
    font-size: 26px;
}
.modalWrapper{
    text-align: center;
}
.optionBlock{
    height: 40px;
    line-height: 40px;
    display: flex;
}
.optionBlock:hover{
    background-color: #e4effc;
}
.optionTitle{
    flex: 2 1;
    text-align: left;
    text-indent: 20px;
    font-weight: 700;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.optionButtons{
    flex: 2;
    display: flex;
}
.optionButton{
    flex:1;
    cursor: pointer;
}

.leadListWrapper{
    width: 90%;
    background-color: #e4effc;
    border: solid 1px #c5d9f0;
    margin-top: 25px;
    color: #02357e;
    -webkit-user-select: none;
    user-select: none;
    display: inline-block;
    border-radius: 10px;
    border-bottom-right-radius: 10px;
    height: calc(95% - 250px);
    border-bottom-left-radius: 10px;
    transition: all .5s;
    margin-left: 5%;
}
.leadListTitle{
    text-align: center;
    font-weight: 700;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 55px;
}
.leadListSort{
    line-height: 55px;
    position:flex;
    font-weight: 700;
    cursor: pointer;
}
.leadListContentWrapper{
    background-color: #ffffff;
    min-height: 250px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    height:100%;
    overflow: auto;
    border: solid 1px #c5d9f0;
}
.minimized{
    width: 80px;
    height: 80px;
    overflow: hidden;
    border-radius: 50%;
    box-shadow: 3px 4px 10px -5px;
    cursor: pointer;
    position: absolute;
    margin-left: 1%;
    min-height: unset;
}
.circleTitle{
    padding-top: 32px;
    padding-bottom: 10px;
    font-weight: 700;
    text-align: center;
}
.filterIcon{
    text-align: center;
    font-size: 22px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 5px;
    margin: auto;
    cursor:pointer;
    display:inline-block;
    vertical-align: top;
}
.saveAsQueue{
    line-height: 30px;
    display: inline-block;
    margin-left: 15px;
    border-radius: 5px;
    font-size: 12px;
    cursor: pointer;
    color: #ffffff;
    font-weight: 700;
    padding: 5px 10px;
}
.queueOptionWrapper{
    width: 90%;
    margin: 0px 20px;
}
.checkAllWrapper{
    position: absolute;
    left: 1%;
    width: 25px;
    height: 25px;
    line-height: 43px;
    z-index: 1;
}