.integrationHolder{
    border: solid 1px #d0d0d0;
    border-radius: 5px;
    background-color: #ffffff;
    text-align: left;
}
.integrationBlock{
    height: 50px;
    line-height: 55px;
    border-bottom: solid 1px #f1f1f1;
}
.integrationLogo{
    width: 30px;
    display: inline-block;
    line-height: 40px;
    height: 40px;
    margin: 5px 20px;
    vertical-align: middle;
}
.integrationTitle{
    font-weight: 700;
    display: inline-block;
    vertical-align: middle;
    font-size: 16px;
    margin-top: -10px;
    min-width: 150px;
    width: 30%;
    text-indent:10px;
}
.integrationStatus{
    line-height: 40px;
    display: inline-block;
    vertical-align: middle;
    margin-top: -10px;
    min-width: 150px;
    width: 30%;
}
.integrationToggleHolder{
    display: inline-block;
    height: 39px;
    vertical-align: middle;
    margin-top: -10px;
}
.integrationDisconnected{
    color: #c20000;
    font-weight: 500;
}
.integrationConnected{
    color: #009100;
    font-weight: 500;
}
.integrationHoler{
    padding: 0px 20px;
    transition: all .5s;
    background-color: #ffffff;
    border-radius: 5px;
    text-align: left;
}
.removeButton{
    border: solid 1px;
    line-height: 35px;
    padding: 0px 10px;
    border-radius: 5px;
    cursor:pointer;
}
.quilitySignInButton{
    width: 180px;
    height: 35px;
    line-height: 35px;
    display: inline-block;
    text-align: center;
    font-weight: normal;
    border-radius: 5px;
    vertical-align: text-bottom;
    cursor:pointer;
}