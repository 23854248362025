.App {
  background-color:var(--background-color);
  min-height: calc(100% - 100px);
  height: -moz-fit-content;
  height: fit-content;
  padding-bottom: 100px;
  font-family: 'Montserrat', sans-serif;
}
#root{
  background-color: var(--background-color);
  height: 100vh;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.cancelEvent{
  border: solid 1px;
    width: 40%;
    margin: auto;
    margin-top: 10px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    border-radius: 5px;
    font-size: 18px;
    background-color: var(--quility-button-color-destructive);
    color: var(--quility-light-text);
    font-weight: bold;
    cursor: pointer;
    max-width: 400px;
}
.cancelEvent:hover{
  background-color: var(--quility-button-color-destructive-hover);
}
.title{
  text-align: center;
  font-size: 30px;
  margin-top: 25px;
  color:var(--quility-dark-text);
  font-weight:700;
}
.subTitle{
  text-align: center;
  font-size: 24px;
  margin: 0px;
  color: var(--quility-dark-text);
}
.signOut{
  display: inline-block;
  padding-left: 20px;
  cursor: pointer;
  color: var(--quility-light-text);
}
.standardButton{
  border: solid 1px;
  width: 40%;
  margin: auto;
  margin-top: 10px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  border-radius: 5px;
  font-size: 18px;
  background-color: var(--quility-color);
  color: var(--quility-light-text);
  font-weight: bold;
  cursor: pointer;
  max-width: 400px;
}
.smallButton{
  border: solid 1px;
  width: 200px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  border-radius: 5px;
  background-color: var(--quility-medium-dark-green);
  margin: 10px auto;
  color: var(--quility-light-text);
  font-weight: 700;
  cursor: pointer;
}
.actionButton{
  border: solid 1px;
  width: 250px;
  margin: 10px auto;
  border-radius: 5px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  background-color: var(--quility-medium-dark-green);
  color: var(--quility-light-text);
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
}
.popupTitle{
  text-align: center;
  font-size: 30px;
  padding-top: 25px;
  color:var(--quility-dark-text);
  font-weight:700;
}
.mainBoxWrapper{
  width: 90%;
  border: solid 1px var(--quility-border-color);
  margin: auto;
  border-radius: 10px;
  min-height: 200px;
  transition: all .3s;
  background-color: var(--background-color);
}
.colorIcon{
  color: var(--quility-button-color);
  font-size: 22px;
}
.outerWrapperIcon{
  position: absolute;
  margin-left: -40px;
}
.mainBoxHeader{
  background-color: var(--quility-light-background);
  height: 50px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.mainBoxTitle{
  line-height: 50px;
  text-align: center;
  color: var(--quility-dark-text);
  font-size: 17px;
  font-weight: 700;
  text-transform: uppercase;
}
.outerWrapper{
  padding: 0px 0px 0px 0px;
  margin: auto;
  border-radius: 10px;
  width: calc(100% - 55px);
  padding-top: 50px;
  margin-left: 50px;
  position: absolute;
  /*height: calc(100% - 50px);*/
  height: fit-content;
  background-color: var(--background-color);
  /*background-image: linear-gradient(to bottom, #00357E 21%, #A2CAFF 100%);*/

}
.outerWrapperTitle{
  color: var(--quility-text-on-background);
  text-align: center;
  display: block;
  line-height: 70px;
  font-weight: 700;
}
.mainBoxContent{
  background-color: var(--background-color);
  min-height: 100px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  height: fit-content;
  padding-bottom: 100px;
}
.noresult{
  font-size: 18px;
  margin-top: 20px;
  font-weight: 700;
}
.wideBlock{
  line-height: 60px;
  display: flex;
  color: var(--quility-dark-text);
  width: 95%;
  border: solid 1px var(--quility-border-color);
  margin: 20px auto;
  border-radius: 10px;
  height: 60px;
}
.wideBlockButton{
  border-radius: 6px;
  color: var(--quility-light-text);
  height: 25px;
  line-height: 25px;
  font-weight: 700;
  width: 40%;
  display: inline-block;
  cursor: pointer;
  max-width: 100px;
  flex: 1;
}

.wideBlock:hover{
  background-color:var(--quility-light-background);
}
.noresultBig{
  text-align: center;
  padding-top: 50px;
  font-size: 22px;
  font-weight: 700;
  color: var(--quility-dark-text);
}
.removeItem{
  background: #1A4981 0% 0% no-repeat padding-box;
  border: 4px solid #426896;
  border-radius: 6px;
  opacity: 1;
  color: var(--quility-light-text);
  line-height: 25px;
  width: -moz-fit-content;
  width: fit-content;
  height: 25px;
  text-align: center;
  font-size: 14px;
  min-width: 25px;
  position: absolute;
  top: -15px;
  right: -15px;
  cursor: pointer;
  font-weight: 900;
  visibility:hidden;
  transition: all .25s;
}
.bigBlock:hover > .removeItem{
  visibility:visible;
}
.called{
  text-decoration: line-through;
  background-color: #eee1e4;
}
.sendingImageWrapper{
  background-color: #e4fffb;
  margin-top: -70px;
  max-height: 100px;
  position: absolute;
  width: calc(100% - 12px);
}
.clickable{
  cursor: pointer;
}
.clickable:hover{
  background-color: var(--quility-hover-grey-color);
}
.sendingImage{
  height: 100%;
  border: solid 3px;
  border-radius: 5px;
}
.innerImageWrapper{
  height: 50px;
    padding: 10px;
    position: relative;
    width: fit-content;
}
.removeImage{
  position: absolute;
  top: 2px;
  right: 2px;
  background-color: #f1f1f1f1;
  width: 15px;
  height: 15px;
  text-align: center;
  line-height: 12px;
  border-radius: 50%;
}
.bigBlock{
  border: solid 0.75px var(--quility-border-color);
  border-radius: 3px;
  padding-bottom: 25px;
  width: 90%;
  margin: auto;
  margin-top: 45px;
  padding-top: 25px;
  margin-bottom: 50px;
  position: relative;
  transition: all .5s;
  text-align: left;
  background-color: var(--quility-nested-block-background-color);
}
.focusBigBlock{
  margin-top: 25px;
  max-height: unset;
  height: fit-content;
}
.roundedBottom{
  padding-bottom: 20px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.closeLead{
  position: absolute;
  right: -8px;
  top: -8px;
  border-radius: 50%;
  border: solid 1px;
  width: 20px;
  height: 20px;
  text-align: center;
  background-color: var(--background-color);
  color: var(--quility-dark-text);
  box-shadow: 3px 3px 10px -5px var(--quility-medium-dark-green);
  user-select: none;
  cursor: pointer;
  z-index: 2;
}
.hideMe{
  display:none;
}
.infoWrapper{
  margin: 10px 60px;
}
.softActive{
  background-color: var(--quility-light-background) !important;
}
.active{
  background-color: var(--quility-extra-dark-green);
  color: #fbfbfb;
}
.standardSelectBox{
  border: 1px solid #707070;
  border-radius: 6px;
  opacity: 1;
  width: 200px;
  height: 28px;
}
.searchInfoWrapper{
  margin-left:40px;
  display:inline-block;
}
.standardInputBox{
  border: 1px solid #707070;
  border-radius: 6px;
  opacity: 1;
  width: 200px;
  height: 28px;
}
.standardInputTitle{
  margin: 7px 0px;
  font-size: 16px;
  font-weight: 700;
}
.noResults{
  display: inline-block;
  text-align: center;
  width: 100%;
  line-height: 100px;
  font-size: 26px;
}
.standardInputWrapper{
  margin: 20px;
}
.x2{
  width: calc(49% - 10px);
  display: inline-block;
  vertical-align: top;
  margin: 0px 10px;
}
.selectionButton{
  border: 4px solid var(--quility-button-color);
  border-radius: 6px;
  opacity: 1;
  width: 200px;
  text-align: center;
  height: 30px;
  line-height: 30px;
  font-weight: 700;
  cursor: pointer;
  user-select: none;
  color:#ffffff;
}
.boxWrappers{
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.quilitySelect{
  border: solid 1px #707070;
  width: 150px;
  border-radius: 5px;
  height: 25px;
}
.accentButton{
  border-radius: 3px;
  width: fit-content;
  height: 32px;
  line-height: 32px;
  padding: 0px 5px;
  cursor: pointer;
  font-size: 14px;
}
.unfocused{
  width: 20%;
}
.focused{
  width:75%;
}
.closed{
  height: 50px !important;
  overflow: hidden !important;
  min-height: unset !important;
  display: block !important;
  margin: 20px auto !important;
}
.displayed{
  display: block;
  margin: 20px auto;
}
.successfulImport{
  display: inline-block;
  margin-left: 40px;
  font-weight: 700;
  margin-top: 20px;
}
.blockFooter{
  height: 50px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  background-color: var(--quility-block-background-color);
}
.dataButtonBlock{
  background: #d0d0d0 0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 1;
  color: var(--quility-light-text);
  line-height: 32px;
  width: -moz-fit-content;
  width: fit-content;
  height: 32px;
  text-align: center;
  font-weight: 700;
  padding: 0px 10px;
  min-width: 50px;
  display: inline-block;
  float: left;
  margin-top: 3px;
  color: black;
  font-size: 12px;
}
.itemTitle{
  font-weight: 700;
}
.innerContentWrapper{
  margin-top: 10px;
}
.hoverIcon{
  margin-left: 10px;
  display: none;
}
.systemInfoItem:hover > .hoverIcon{
  display:inline-block;
}
.actionButtonBlock{
  float: right;
  margin-right: 15px;
}
.buttonWrap{
  display: inline-block;
}
.left{
  width:50%;
  height: 100%;
  vertical-align: middle;
}
.right{
  width:50%;
  height: 100%;
  vertical-align: middle;
}
.accentModule{
  font-size: 16px;
  color: #313131;
  font-weight: 700;
  margin: 20px auto;
  cursor: pointer;
  line-height: 40px;
  width: 90%;
  border-radius: 12px;
  background-color: #F0EDFD;
  border: 1px solid #DCE5EF;
  height: 50px;
  overflow: hidden;
  transition:all .5s;
}
.accentModuleTitle{
  height: 50px;
  line-height: 50px;
  text-indent: 25px;
}
.accentModuleContent{
  text-indent: 0px;
}
.accentColor{
  background: #FF8557 0% 0% no-repeat padding-box;
  border: 4px solid #ff9a72;
  border-radius: 6px;
  color:#ffffff;
}
.mainBlockContent{
  background-color: #ffffff;
  min-height: 200px;
  max-height: 530px;

  overflow: auto;
  transition: all .5s;
}
.mainBlockTitle{
  position: relative;
  height: 40px;
  text-align: left;
  font-size: 18px;
  line-height: 40px;
  font-weight: 700;
  text-transform: capitalize;
  text-indent: 20px;
}
.mainBlockHeader{
  background-color: var(--quility-light-background);
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  height: 40px;
  color: var(--quility-dark-text);
  -webkit-user-select: none;
  user-select: none;
  transition: all .5s;
}
.mainBlockWrapper{
  margin: 20px 20px;
  width: 75%;
  max-width: 1500px;
  transition: all .5s;
  position: relative;
  border: solid 1px #d0d0d0;
  border-radius: 5px;
}

.mainBlockOuterWrapper{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.focusMainBlock{
  width: 90% !important;
  max-width: 1500px !important;
  margin: 10px auto !important;
  height: fit-content;
  position: relative;
  flex: unset;
}
.twoX{
  flex: 1;
  min-width: 30%;
  height:fit-content;
  max-width: 40%;
}
.largeTextarea{
  width: 80%;
  height: 220px;
  border-radius: 10px;
}
.closeMainBlock{
  width: 70% !important;
  margin: 10px auto !important;
  max-width: unset !important;
  height: 35px !important;
  overflow: hidden !important;
  flex: unset !important;
}
.accentModuleSelectOption{
  margin-left: 50px;
  height: 40px;
  line-height: 40px;
}
.mainModule{
  font-size: 16px;
  color: var(--quility-dark-text);
  font-weight: 700;
  margin: 20px;
  cursor: pointer;
  line-height: 50px;
  width: 90%;
  margin-left: 40px;
  border-radius: 12px;
  background-color: #D9F2ED;
  text-indent: 16px;
  border: 1px solid #DCE5EF;
  height: 50px;
  overflow: hidden;
  transition: all .5s;
}
.mainModuleContent{
  overflow: auto;
  height: 250px;
}
.openModule{
  height:300px;
}
.closeWindow{
  background: var(--quility-extra-dark-green) 0% 0% no-repeat padding-box;
  border: 4px solid var(--quility-medium-dark-green);
  border-radius: 6px;
  opacity: 1;
  color: #ffffff;
  line-height: 25px;
  width: -moz-fit-content;
  width: fit-content;
  height: 25px;
  text-align: center;
  font-size: 14px;
  min-width: 25px;
  position: absolute;
  top: 5px;
  right: 10px;
  cursor: pointer;
  font-weight: 900;
}
.closeIcon{
  height: 43px;
}
.quilityDestructiveClose{

  border-radius: 50%;
  opacity: 1;
  color: var(--quility-button-color-destructive);
  line-height: 30px;
  width: -moz-fit-content;
  width: fit-content;
  height: 30px;
  text-align: center;
  font-size: 14px;
  min-width: 30px;
  position: absolute;
  /*top: 5px;*/
  right: 10px;
  cursor: pointer;
  font-weight: 900;
}
.quilityCloseWindow{
  /*border: 2px solid var(--quility-close-button-color);*/
  border-radius: 50%;
  opacity: 1;
  color: var(--quility-close-button-color);
  line-height: 30px;
  width: -moz-fit-content;
  width: fit-content;
  height: 30px;
  text-align: center;
  font-size: 14px;
  min-width: 30px;
  position: absolute;
  /*top: 5px;*/
  right: 10px;
  cursor: pointer;
  font-weight: 900;
}

/**** Toggle Switch ***/
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  line-height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--quility-deactive-color);
    transition: .4s;
    border-radius: 5px;
    border: solid 1px var(--quility-border-color);
}

.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 14px;
  left: 4px;
  bottom: 2.5px;
  background-color: var(--background-color);
  transition: .4s;
  border-radius: 3px;
}
.toggleTitle{
  display: inline-block;
  font-size: 14px;
}
.actualToggleWrapper{
  display: inline-block;
  line-height: 24px;
  padding: 0px 10px;
}
.toggleWrapper{
  border: solid 1px;
  line-height: 25px;
  border-radius: 5px;
  display: inline-block;
  padding-left: 5px;
  margin-left: 10px;
  border-color: var(--quility-border-color);
  font-weight: 700;
  box-shadow: 2px 2px 10px -7px;
  background-color: var(--quility-block-header-background-color);
  margin-top: 2px;
}
.activeToggle{
  background-image: linear-gradient(to right, var(--quility-color), #ffffff);
}
input:checked + .slider {
  background-color: var(--quility-color);
}
.sliderDesc{
  margin-left: 20px;
  vertical-align: sub;
}
input:focus + .slider {
  box-shadow: 0 0 1px var(--quility-light-hover-color);
}

input:checked + .slider:before {
  -webkit-transform: translateX(17px);
  -ms-transform: translateX(17px);
  transform: translateX(17px);
}
.variableHolder{
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: space-around;
  margin: 10px;
}
.variableWrapper{
  border: solid 1px;
  width: 200px;
  border-radius: 5px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
  box-shadow: 2px 2px 10px -5px;
  position: relative;
}
.variables{
  position: absolute;
  width: 200px;
  background-color: var(---quility-block-header-background-color);
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  transition: all .5s;
  max-height: 200px;
  height: 0px;
  overflow: hidden;
}
.systemInfoItem{
  margin: 10px 0px;
  font-size: 14px;
}
.viewVariables{
  height: 200px;
  border-radius: 5px;
  border: solid 1px;
  box-shadow: 3px 3px 10px -5px;
  margin-left: -1px;
  overflow: auto;
}
.largeInput{
  width:80%;
}
.hoverInfo{
  border: solid 1px;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  position: absolute;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  margin-left: 5px;
  cursor: pointer;
  text-indent: 0px;
  box-shadow: 3px 3px 10px -5px var(--quility-medium-dark-green);
}
.hoverContent{
  position: absolute;
  margin-left: 10px;
  border: solid 1px;
  width: 200px;
  padding: 5px;
  border-radius: 5px;
  background-color: #dedede;
  display: none;
  box-shadow: 0px 0px 22px -6px;
  z-index: 2;
  white-space: break-spaces;
  color: var(--quility-dark-text);
}
.hoverInfo:hover > .hoverContent {
  display: inline-block;
}
.errorMessage{
  margin: 5px 0px;
  color: #de0000;
  font-weight: 700;
}
.innerBlockItem{
  height: 40px;
  line-height: 40px;
  width: 80%;
  margin: 10px auto;
  background: #E2EDFA 0% 0% no-repeat padding-box;
  border: 1px solid #D9D9D9;
  border-radius: 12px;
}
.navBlock{
  width: 95%;
  display: inline-block;
  vertical-align: top;
  max-width: 700px;
  margin: 20px 0px;
  transition: all .5s;
}
.navBlock:nth-child(even) {

}
.navBlock:nth-child(odd) {

}
.leftSideNav{
  display: inline-block;
  width: calc(50% - 15%);
  vertical-align: top;
  transition:all .5s;
}
.rightSideNav{
  display: inline-block;
  width: 50%;
  vertical-align: top;
}
.leftSideNav:hover{
  width:45%;
}
.navBlock:hover{
  width: 100% !important;
}
.leftSideNav:hover > .navBlock{
  width:85%;
}
.selectable:hover{
  outline: solid 4px var(--quility-light-hover-color);
  cursor:pointer;
}
.clearFooter{
  height: 50px;
  background-color: #ffffff;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}
.bottomAccentButton{
  border-radius: 20px;
  opacity: 1;
  width: -moz-fit-content;
  width: fit-content;
  color: #ffffff;
  font-weight: 700;
  height: 32px;
  line-height: 32px;
  padding: 0px 10px;
  cursor: pointer;
  font-size: 12px;
  color: black;
  background-color: #08c9cd;
  float: right;
  margin-right: 10px;
  margin-top: 9px;
}
.previewBlock{
  background-color: #f1f1f1;
  height: 50px;
  line-height: 50px;
  width: 80%;
  margin: 10px auto;
  border-radius: 5px;
  color: var(--quility-dark-text);
  border: solid 1px var(--quility-border-color);
}
.previewBlockTitle{
  margin-left: 20px;
}
.editLeadInputBox{
  border: 1px solid #707070;
  border-radius: 6px;
  opacity: 1;
  width: 150px;
  height: 22px;
  font-size: inherit;
  color: inherit;
  font-family: inherit;
}

.purchasePageWrapper{
  height: 600px;
}
.purchasePageWrapper{
  height: 600px;
  width: 100%;
}
.blockWrapper{
  border: 1px solid var(--quility-border-color);
  border-radius: 5px;
  margin: 50px auto auto;
  width: 60%;
}
.blockHeader{
  background-color: var(--quility-nested-block-header-background-color);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  line-height: 50px;
}
.blockContent{
  background-color: var(--quility-block-background-color);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 450px;
}
.purchaseSectionWrapper{
  text-align: center;
  padding: 10px;
}
.inputWrapper{
  margin-top: 5px;
}
.purchaseSectionTitle{
  font-weight: 700;
  font-size: 16px;
}
.loadingScreen{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: var(--quility-veil);
}
.innerWrapper{
  position: relative;
  text-indent: 0px;
  margin: 0px 10px;
  color: var(--quility-dark-text);
}
.leaderBoardTab{
  width: 40px;
    height: 40px;
    position: fixed;
    margin-top: 270px;
    text-align: center;
    line-height: 40px;
    background-color: var(--quility-dark-green);
    color: var(--quility-light-text);
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-left: 0px;
    box-shadow: 3px 3px 10px -7px;
    cursor: pointer;
    transition: all .5s;
    z-index: 1;
    right: 0;
}
.leaderBoardTab:hover {
  width: 200px;
}
.headerTitle{
  text-align: center;
  line-height: 40px;
  font-size: 20px;
  font-weight: 700;
}
.messagesTab{
  width: 40px;
    height: 40px;
    position: fixed;
    margin-top: 325px;
    text-align: center;
    line-height: 40px;
    background-color: var(--quility-dark-green);
    color: var(--quility-light-text);
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-left: 0px;
    box-shadow: 3px 3px 10px -7px;
    cursor: pointer;
    transition: all .5s;
    z-index: 3;
    right: 0;
}
.messagesTab:hover {
  width: 200px;
}
.callLogTab{
  width: 40px;
    height: 40px;
    position: fixed;
    margin-top: 380px;
    text-align: center;
    line-height: 40px;
    background-color: var(--quility-dark-green);
    color: var(--quility-light-text);
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-left: 0px;
    box-shadow: 3px 3px 10px -7px;
    cursor: pointer;
    transition: all .5s;
    z-index: 3;
    right: 0;
}
.callLogTab:hover {
  width: 200px;
}
.hiddenOptions{
  position: absolute;
  display: none;
  margin-left: -50px;
  background-color: var(--quility-dark-green);
  margin-top: -10px;
  width: 111px;
  box-shadow: -6px 5px 15px -5px vvar(--quility-dark-green);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  color: var(--quility-light-text);
}

.BarOption{
  height: 50px;
  line-height: 50px;
  cursor:pointer;
}
.BarOption:hover{
  background-color:var(--quility-dark-hover-color);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  color: var(--quility-dark-text);
}
.showAlertWrapper{
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  width: 200px;
  background-color: #2e3035;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  margin: auto;
  display: inline-block;
  margin-left: calc(50% - 100px);
  border-top: none;
  height: 70px;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  line-height: 30px;
  box-shadow: 3px 3px 15px -5px #2e3035;
  color: #49cf49;
}
.fileText{
  width: fit-content;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-indent: 15px;
  text-align: left;
  position: relative;
  max-width: 80%;
  padding-right: 25px;
}
.removeIcon{
  position: absolute;
  margin-top: -7px;
  cursor: pointer;
  top: 0;
  right: 0;
}
.bugReportHolder{
  color: var(--quility-light-text);
  font-weight: 700;
  cursor: pointer;
  line-height: 50px;
  display: inline-block;
  vertical-align: middle;
}
.bugReportWrapper{
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--secondary-color);
  width: 80%;
  height: 600px;
  z-index: 2;
  margin-left: 10%;
  margin-top: 100px;
  border-radius: 10px;
  box-shadow: 0px 0px 200px 0px var(--secondary-color);
}
.bugInput{
  width: 600px;
  border-radius: 5px;
  border: solid 0.5px;
  height: 30px;
  font-family: inherit;
  color:inherit;
}
.reportContent{
  width: 80%;
  margin: auto;
  max-width: 600px;
}
.thanks{
  position: absolute;
  top: 0;
  background-color: #434141;
  color: #00b060;
  font-weight: 700;
  height: 60px;
  width: 200px;
  text-align: center;
  margin-left: 50%;
  left: 0;
  transform: translateX(-50%);
  font-size: 20px;
  padding: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  line-height: 27px;
  transition:all .5s;
  opacity:0;
}
.showThanks{
  opacity:1;
}
.loaderWrapper{
  width: 200px;
  margin: auto;
  margin-top: 25%;
}
.newAlertHeader{
  line-height: 30px;
  background-color: var(--quility-accent-background);
  font-weight: 700;
  text-indent: 10px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}
.newAlertWrapper{
  position: fixed;
  background-color: var(--quility-block-background-color);
  width: 400px;
  border-radius: 5px;
  border: solid 1px var(--quility-border-color);
  top: 5px;
  right: -430px;
  box-shadow: 0px 0px 20px;
  transition: all .3s;
  z-index: 4;
}
.newAlertContent{
  padding: 5px 10px;
}
.newAlertLineTitle{
  width: 80px;
  display: inline-block;
  font-weight: 700;
}
.newAlertLine{
  margin: 10px 0px;
}
.displayMessageAlert{
  right: 5px !important;
}
.alerts{
  position: absolute;
  width: 20px;
  height: 20px;
  line-height: 20px;
  border-radius: 50%;
  font-size: 12px;
  background-color: #c70404;
  font-weight: 700;
  margin-left: -30px;
}
.unreadTitle{
  color: #ffffff !important;
  background-color: var(--secondary-color);
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}
.unreadContent{
  background-color: #bebebe !important;
}
.smallClose{
  height: 20px !important;
  width: 20px !important;
  line-height: 19px !important;
  display: inline-block !important;
  min-width: unset !important;
  right: -30px !important;
  margin-top: -2px !important;
}
.twoBlocks{
  display: inline-block;
  height: 100%;
  margin-top: 25px;
}
.solidQuilityActiveButton{
  background-color:var(--quility-button-color);
  color:var(--quility-color-button-text-color) !important;
  border-color:var(--quility-color-button-text-color) !important;
  font-weight: 700;
}
.solidQuilityActiveButton:hover{
  background-color:var(--quility-button-color-hover);
}
.outlineQuilityActiveButton{
  color:var(--quility-color);
  border-color:var(--quility-color) !important;
  border:solid 1px;
}
.outlineQuilityActiveButton:hover{
  border-color: var(--quility-dark-green);
  color: var(--quility-dark-green);
}
.outlineQuilityInactiveButton{
  color:var(--quility-input-border-color) !important;
  border-color:var(--quility-input-border-color) !important;
  border:solid 1px;
}
.solidQuilityDestructiveButton{
  background-color:var(--quility-button-color-destructive);
  border-color:var(--quility-button-color-destructive) !important;
  color:#ffffff;
}
.oulineQuilityDestructiveButton{
  color:var(--quility-button-color-destructive);
  border-color:var(--quility-button-color-destructive) !important;
  z-index: 1;
}

.solidQuilityDestructiveButton:hover{
  background-color:var(--quility-button-color-destructive-hover);
}
.oulineQuilityDestructiveButton:hover{
  color:var(--quility-button-color-destructive-hover);
  border-color:var(--quility-button-color-destructive-hover)!important;
}
input[type=checkbox] {
  accent-color: var(--quility-button-color);
}
.chosen{
  background-color:#daf3f0;
}
.quilityActive{
  background-color:var(--quility-button-color-hover);
  color:var(--quility-light-text);
}
.quilityLongButton{
  border: solid 1px  var(--quility-button-color);
  width: calc(95% - 20px);
  margin: auto;
  height: 30px;
  line-height: 30px;
  border-radius: 5px;
  text-align: center;
  font-weight: 700;
  user-select: none;
  cursor:pointer;
  background-color: var(--quility-button-color);
  color: var(--quility-color-button-text-color);
}
.quilitySmallInlineButton{
  display: inline-block;
  line-height: 20px;
  vertical-align: revert;
  width: fit-content;
  border: none;
  margin-top: 2px;
  font-size: 12px;
  padding: 2px 10px;
  border-radius: 5px;
  color: white;
  background-color: var(--quility-color);
  font-weight: 700;
  cursor:pointer;
}
.quickError{
  color: red;
  font-weight: 700;
  margin-bottom: 10px;
}
.deleteItem{
  border-radius: 50%;
  opacity: 1;
  line-height: 25px;
  width: -moz-fit-content;
  width: fit-content;
  height: 25px;
  text-align: center;
  font-size: 14px;
  min-width: 25px;
  position: absolute;
  top: 2px;
  right: 10px;
  cursor: pointer;
  display:none;
}
.quilityNestedBlockWrapper:hover > .deleteItem{
  display:block;
}
.forgotPassword{
  cursor:pointer;
  text-decoration: underline;
}
.tabIcon{
  display: inline-block;
  vertical-align: top;
}
.tabTitle{
  width:0px;
  overflow:hidden;
  display: inline-block;
  vertical-align: top;
  transition: all .5s;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.innerTab:hover > .tabTitle{
  width:150px;
  transition: all .5s;
}
.frontColumn{
  display: inline-block;
  text-align: center;
  vertical-align: top;
}
.centerColumn{
  width:50%;
}
.sideColumn{
  width:25%;
  margin-top: 25px;
}
.textOverflowControl{
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.smallQuilityRemove{
  position: absolute;
    margin-left: 20px;
    width: 18px;
    height: 18px;
    margin-top: -12px;
    border-radius: 50%;
    text-align: center;
    line-height: 16px;
    font-size: 15px;
    cursor: pointer;
}
.oppositeColor{
  /*color: var(--quility-block-header-background-color);
  border-color: var(--quility-block-header-background-color);*/
}
.termsLink{
  color: black;
  font-size: 18px;
  margin-left: 15px;
}
.description{
  margin: 15px 0px;
  font-size: 16px;
  font-weight: 700;
}

.solidQuilityInactiveButton{
    background-color:var(--background-color);
    color:var(--quility-color-button-text-color);
    font-weight: 700;
  }

.submissionHelperHeader{
    font-weight: 700;
    margin-bottom: 10px;
    margin-top: 10px;
    text-decoration: underline;
}

.submissionHelperMessage{
    font-weight: 700;
    margin-bottom: 10px;
}