.recordingItem{
    text-indent: 0px;
    margin-left: 80px;
    width: calc(100% - 80px);
}
.audioPlayerStyles{
    width: 50%;
}
.audioElementAdjustment{
    margin-bottom:30px;
    text-align: center;
}
.recordedWrapper{
    margin:20px;
}
.audio-recorder{
    margin: 10px auto;
}
.adjust{
    margin:auto;
    margin-top:15px;
}
.deleteRecording{
    border-radius: 50%;
    opacity: 1;
    line-height: 25px;
    width: -moz-fit-content;
    width: fit-content;
    height: 25px;
    text-align: center;
    font-size: 14px;
    min-width: 25px;
    position: absolute;
    top: 2px;
    right: 10px;
    cursor: pointer;
}