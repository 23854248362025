.extraIndent{
    text-indent: 20px;
    font-weight: unset;
}
.stateOptionsList{
    border: solid 1px;
    width: 260px;
    margin: auto;
    text-align: left;
    text-indent: 10px;
    max-height: 250px;
    height:fit-content;
    position: absolute;
    margin-left: 28%;
    transform: translateX(-50%);
    background-color: #ffffff;
    overflow: auto;
    border-radius: 5px;
    z-index: 2;
}
.addCardPopup{
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #80808045;
    top: 0;
    left: 0;
    align-content: space-evenly;
    padding-left: 13%;
    z-index: 6;
}
.error{
    color: red;
    font-weight: 700;
}
.option{
    padding: 5px;
    cursor:pointer;
}
.option:hover{
    background: #f1f1f1;
}
.orderButton{
    width: 140px;
    line-height: 30px;
    display: inline-block;
    margin-left: 15px;
    border-radius: 5px;
    font-size: 15px;
    cursor: pointer;
    color: #ffffff;
    font-weight: 700;
    padding: 5px 10px;
    margin-top: 20px;
}
.price{    
    margin: 10px;
    font-weight: 700;
}
.submitPaymentFormWrapper{
    width: fit-content;
    margin: 20px auto;
}
.totalPrice{
    margin: 15px 0px;
}
.selectCards{
    width: 310px;
    margin: auto;
    font-family: inherit;
}