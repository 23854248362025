.leadCardWrapper{
    /*width: 81%;
    margin: auto;
    border: solid 2px var(--quility-button-color);
    height: calc(100% - 220px);
    position: absolute;
    margin-left: 10%;
    margin-top: -100px;
    border-radius: 5px;
    box-shadow: 0px 1px 150px 50px var(--quility-burst-color);
    top: 180px;
    z-index: 4;
    background-color: var(--quility-block-header-background-color);
    min-height: 400px;*/
    width: 92%;
    margin: auto;
    border: solid 2px var(--quility-button-color);
    height: calc(100% - 50px);
    position: fixed;
    margin-left: 4%;
    margin-top: -100px;
    border-radius: 5px;
    box-shadow: 0px 1px 150px 50px var(--quility-burst-color);
    top: 120px;
    z-index: 4;
    background-color: var(--quility-block-header-background-color);
    min-height: 400px;
}
.showMe{
    display: inline-block;
    height: 97%;
}
.appointmentWrap{
    margin: 20px 10px;
    border: solid 1px var(--quility-border-color);
    border-radius: var(--quility-border-radius);
}
.recordingWrapper{
    width: 94%;
    margin: 3%;
}
.returnToLeadCard{
    height: 30px;
    line-height: 30px;
    cursor: pointer;
}
.leadCardTopBar{
    height: 50px;
    background-color: var(--quility-dark-green);
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    display: flex;
    color: #ffffff;
}
.leadCardDataWrapper{
    height: 100%;
    display: flex;
}
.leadCardContent{
    background-color: var(--quility-block-header-background-color);
    height: calc(100% - 60px);
    display: inline-block;
    width: 100%;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    border-top: 0;
}
.leadDataBlock{
    margin: 5px 0px;
    width: 100%;
    margin-left: 25%;
}
.columnName{
    width: 120px;
    color: grey;
    display: inline-block;
    text-transform: capitalize;
}
.leadDataText {
    color: var(--quility-dark-text);
}
.columnTitle{
    text-align: center;
    font-size: 22px;
    margin: 5px;
    margin-bottom: 20px;
    font-weight: 700;
    color: var(--quility-dark-text);
}
.editHover {
    padding-left: 10px;
    color: #0000005c;
    display: none;
}
.clickToEdit {
    cursor: pointer;
    border-bottom: solid 1px;
}
.clicktoedit{
    color: grey;
}
.clickToEdit:hover + .editHover {
    display: inline-block;

}
.leftColumn{
    width: 50%;
    display: inline-block;
    vertical-align: top;
    margin: auto;
}
.rightColumn{
    width: 50%;
    display: inline-block;
    vertical-align: top;
}
.leftDataBlock{
    margin: 5px 0px;
    width: 100%;
    margin-left: 25%;
}
.columnWrapper{
    width: 80%;
    margin: auto;
}
.leadNameTitle{
    font-size: 30px;
    text-align: center;
    margin: 20px;
    color: var(--quility-dark-text);
}   
.propertyAddress{
    text-align: center;
    margin: 10px;
    margin-top: 0px;
}
.focusAddress{
    margin: 0px 3px;
}
.mapsLink {
    color: var(--quility-dark-text);
    font-size: 22px;
    position: absolute;
    margin-left: 16px;
    margin-top: -5px;
}
.leadTimezone{
    text-align: center;
    font-weight: 700;
}
.focusLastContact {
    text-align: center;
    margin: 5px 0px 5px 0px;
}
.leadTypeLabels{
    text-align: center;
}
.barButtonWrapper{
    flex:4;
    display: flex;
}
.leadCardBarOperations{
    flex: .25 1;
    display: flex;
    padding-left: 15px;
}
.leadCardBarMessages{
    flex: .5;
    display: flex;
    padding-right:15px;
}
.leadCardTopBarButton{
    flex: 1 1;
    line-height: 50px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: center;
    cursor: pointer;
    font-size: 14px;
    font-weight: 700;
}
.leadCardTopBarButton:hover{
    background-color:var(--quility-dark-hover-color);
}
.leadCardTitle{
    font-size: 31px;
    text-align: center;
    margin: 5px;
}
.cardBarMessageBlock{
    flex: 1;
    text-align: center;
    line-height: 50px;
    font-size: 20px;
    cursor:pointer;
}
.cardOperationsBlock{
    flex: 1;
    text-align: center;
    line-height: 60px;
    color: #fbfbfb;
    font-size: 20px;
    cursor:pointer;
}

.leadNotesContentWrapper{
    background-color: #fbfbfb;
    width: 70%;
    position: absolute;
    border-radius: 10px;
    border: solid 1px var(--quility-border-color);
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
    box-shadow: 3px 3px 10px -5px #02357e;
    height: 0px;
    cursor: default;
    transition: all .5s;
    overflow: hidden;
    z-index: 1;
    margin-top: 50px;
    color: var(--quility-dark-text);
}
.open{
    height:70%;
}
#leadNotes2{
    height: 70%;
    border: solid 1px var(--quility-border-color);
    width: 96%;
    margin: auto;
    border-radius: 10px;
    padding: 5px;
    overflow: auto;
}
.openConvo{
    height:calc(90% - 60px) !important;
    min-height: 380px;
}
.conversationWrapper{
    position: absolute;
    height: 0%;
    transition: all .5s;
    border: solid 1px;
    width: 40%;
    background-color: #fbfbfb;
    margin-top: 50px;
    border-radius: 10px;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
    overflow: auto;
    border: solid 1px var(--quility-border-color);
    box-shadow: 3px 3px 10px -5px #02357e;
}
.leadTopBarWrapper{
    background-color: var(--quility-block-header-background-color);
    font-weight: 700;
}
.leadCardButtonOuterWrapper{
    flex: 1 1 0%;
    line-height: 30px;
    text-align: center;
    color: var(--quility-dark-text);
    cursor: pointer;
    min-width: 20%;
}
.pdfLink{
    height: 100%;
    display: inline-block;
    width: 100%;
    text-decoration: none;
    color: inherit;
}
.leadTopBarButton{
    /*flex: 1 1;
    background-color: #00357f;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    margin: 0px 2px;
    text-align: center;
    line-height: 50px;
    color: #ffffff;
    font-weight: 700;
    cursor: pointer;
    user-select: none;
    background-color: #fafafa00;
    transition:all .5s;*/
    transition: all .5s;
    white-space: nowrap;
    overflow: hidden;
    margin: 5px;
    text-align: center;
    line-height: normal;
    padding: 8px 0px;
    text-overflow: ellipsis;
    border: solid 1px var(--quility-button-color);
    color: var(--quility-button-color);
    font-size: 14px;
    border-radius: 3px;
}
.dialogBox{
    background-color: #ffffff;
    width: 98%;
    position: absolute;
    left: 1%;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    height: 0px;
    transition: all .5s;
    overflow: hidden;
    z-index: 1;
    box-shadow: 3px 8px 20px -7px #000000;
    margin-top: 1px;
    color: var(--quility-color);
}
.openBox{
    height: 300px;
}
.leadCardHeader{
    line-height: 60px;
    background-color: var(--quility-block-header-background-color);
}
.queueBlockOption{
    padding: 10px 0px;
    font-size: 13px;
    border-bottom: solid 1px #f1f1f1;
}
.leadCardMainWrapper{
    width: 100%;
    margin: auto;
    height: calc(100% - 85px);
}
.leadTopBarInnerWrapper{
    display: flex;
    width: 98%;
    margin: auto;
}
.leadTypeButton{
    position: absolute;
    right: 20px;
    top: 25px;
    border: solid 3px #ffdcb4;
    line-height: 30px;
    border-radius: 5px;
    padding: 0px 10px;
    background-color: #ffecd6;
}
.numberChoice{
    line-height: 26px;
    padding: 2px 5px;
    color: var(--quility-dark-text);
    cursor:pointer;
}
.numberChoice:hover{
    background-color: var(--quility-dark-green);
    color: var(--quility-light-text);
}
.numberOptions{
    position: absolute;
    background-color: var(--quility-block-background-color);
    border: solid 1px var(--quility-border-color);
    border-radius: 5px;
    margin-left: 30px;
    font-size: 14px;
    box-shadow: 3px 3px 10px -4px var(--quility-burst-color);
    display: none;
    transition: all .5s;
    margin-top: -70px;
    max-height: 150px;
    overflow: auto;
}
.callIcon:hover > .numberOptions{
    display: block;
    width: fit-content;
    min-width: 120px;
}
.leadCardAddressHolder{
    text-align: left;
    text-indent: 25px;
    font-size: 14px;
    display: inline-block;
    width: 60%;
    display: block;
    text-indent: 50px;
    margin-bottom: -10px;
    margin-top: 5px;
}
.removeLead{
    border: solid 1px;
    position: absolute;
    right: 7px;
    margin-top: -25px;
    line-height: 25px;
    padding: 2px 10px;
    border-radius: 5px;
    font-size: 13px;
    cursor:pointer;
}
.divider{
    width: 100%;
    height: 2px;
    background-color: #002353;
}
.appointmentsWrapper{
    position: absolute;
    transition: all 1s;
    line-height: normal;
    z-index: 2;
    right: -288px;
    margin-top: -10px; 
}
.appointmentsWrapper:hover{
    right: 5px;
}
.appointmentWrapperContent{
    margin-left: 50px;
    height: 400px;
    background-color: #ffffff;
    border: solid 1px var(--quility-border-color);
    border-radius: 5px;
    margin-top: -27px;
    box-shadow: -5px 0px 20px -15px;
    width: 280px;
    overflow: auto;
}

.exclusivity{
    margin-top: 20px;
    margin-left: 30px;
    line-height: normal;
}
.expiring{
    background-color:#ffff7e1c;
}
.expired{
    background-color:#ff000017;
}
.exclusive{
    background-color:#8aff8a14;
}