.kanban-board {
    display: flex;
  }
  
  .column {
    margin: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    flex-grow: 1;
  }
  
  .task-list {
    padding: 8px;
  }
  
.task {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 8px;
    padding: 8px;
}
.colorfbb14b{
    border-color:#fbb14b !important;
}
.color98bcba{
    border-color:#98bcba !important;
}
.color8cdba9{
    border-color: #8cdba9 !important;
}
.colorbaa26c{
    border-color:#baa26c !important;
}
.dragCard{
    padding: 16px;
    background-color: #ffffff;
    border-radius: 5px;
    margin: 10px;
    cursor: grab;
    -webkit-user-select: none;
    user-select: none;
    border-left: solid 4px;
}
.kanBandListTitle{
    background-color: #0b4364;
    color: #ffffff;
    font-weight: 700;
    padding: 5px;
}
.kanbanBorderWrapper{
    background-color: #0b4364;
    padding: 10px;
    display: flex;
}
.buttomButtonHolder{
    display: flex;
}
.kanName{
    font-size: 15px;
    font-weight: 700;
}
.kanLeadType{
    
}
.kanStandardItem{
    font-size: 14px;
    border-radius: 5px;
    background-color: #0b4365;
    color: #ffffff;
    padding: 4px 5px;
    width: -moz-fit-content;
    width: fit-content;
    margin: 4px 0px;
}
.kanFlow{

}
.kanTagsHolder{
    font-size: 13px;
}
.kanTag{
    display: inline-block;
    border-radius: 5px;
    padding: 2px 5px;
    background-color: #d2d2d2;
    margin: 0px 3px;
}
.kanColumn{
    width: 300px;
    background-color: #587f95;
    border-radius: 5px;
    flex: 1 1;
    margin: 5px 5px;
    padding-bottom: 10px;
}
.kanCardButton{
    margin: 5px;
    display: inline-block;
    border-radius: 5px;
    padding: 5px;
    font-size: 13px;
    width: 15px;
    height: 15px;
    border: solid 1px #c8c8c8;
}
.bottomButtonHolder{
    display: flex;
    margin-top: 10px;
    margin-bottom: -10px;
}