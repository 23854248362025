.typeBlock{
    display: inline-block;
    font-size: 12px;
    border: 0.5px solid #d0d0d0;
    height: 25px;
    line-height: 25px;
    vertical-align: middle;
    margin: -4px 10px 0px;
    text-indent: 0px;
    border-radius: 20px;
    background-color: #d0d0d0;
    padding: 0px 5px;
}
.dashSectionBlockTitle{
    width: 175px;
    display: inline-block;
    text-align: left;
    vertical-align: middle;
    font-size: 16px;
}
.typeWrapper{
    display: inline-block;
    text-align: center;
    vertical-align: middle;
}
.runQueue{
    display: inline-block;
    position: absolute;
    margin-left: 40px;
    margin-top: 5px;
    width: 90px;
}
.dashSelectionInfoTitle{
    font-size: 20px;
    line-height: 45px;
}
.innerWrapperSection{
    overflow: auto;
    height: 100%;
}
.dataBlock{
    background-color: #f1f1f1;
    color: black;
    padding: 1px 5px;
    border-radius: 5px;
    margin: 0px 5px;
}
.suggestionDrop{
    position: absolute;
    width: 85%;
    text-align: center;
    border: solid 0.5px;
    margin: auto;
    border-top: none;
    margin-left: 13px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    box-shadow: 3px 3px 10px -5px;
    margin-top: -3px;
    background-color: #ffffff;
    max-height: 300px;
    overflow: auto;
    z-index: 1;
}
.filterSelected{
    display: inline-block;
    border: solid 1px;
    background-color: #1A4981;
    color: #ffff;
    padding: 4px 9px;
    border-radius: 5px;
    margin: 5px 5px;
    cursor: pointer;
    position: relative;
}
.smallDescription{
    margin-top: -10px;
    font-size: 13px;
    margin-bottom: 15px;
}
.filterSelected:hover > .removeFilterElement{
    visibility: visible;
}
.suggestionResult{
    display: block;
    text-align: left;
    text-indent: 10px;
    cursor: pointer;
    padding: 5px 0px;
    transition:all .5s;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.suggestionResult:hover{
    background-color: #d3e7f3;
}
.filterInputHolder{
    font-size: 18px;
    width: 100%;
    display: inline-block;
    position: relative;
}
.searchBlockInput{
    height: 25px;
    width: calc(96% - 50px);
    border-radius: 5px;
    border: solid 0.5px;
    font-family: inherit;
    color: inherit;
    font-size: 16px;
}
.arrowSupp{
    float: left;
    margin-left: 20px;
    margin-right: -131px;
    margin-top: 15px;
    padding: 0px 10px;
    cursor: pointer;
    height: 30px;
    line-height: 30px;
}
.statusCounts{
    border: solid 0.5px #96d8ff;
    background-color: #edf7fd;
    border-radius: 5px;
    height: 35px;
    line-height: 35px;
    width: fit-content;
    text-indent: 0px;
    padding: 0px 8px;
    font-size: 14px;
    margin: 10px 0px;
    display: inline-block;
}
.addSearchToQueue{
    display: inline-block;
    position: absolute;
    margin-left: 40px;
    margin-top: 5px;
    width: 160px;
}
.addToQueue{
    font-size: 18px;
    color: #1A4981;
    margin-right: 20px;
    text-indent: 0px;
    margin-left: 10px;
    width: 35px;
    display: inline-block;
    text-align: center;
    height: 35px;
    line-height: 35px;
    border-radius: 50%;
    transition: all .5s;
    position: relative;
}
.addToQueue:hover {
    background-color: #1A4981;
    color: #ffffff;
}
.addToQueue2{
    font-size: 18px;
    color: #1A4981;
    margin-right: 20px;
    text-indent: 0px;
    margin-left: 10px;
    width: 35px;
    display: inline-block;
    text-align: center;
    height: 35px;
    line-height: 35px;
    border-radius: 50%;
    transition: all .5s;
    position: relative;
}
.addToQueue2:hover {
    background-color: #1A4981;
    color: #ffffff;
}
.hoverHolder{
    position: absolute;
    font-size: 14px;
    margin-left: -35px;
    margin-top: 0px;
    background-color: #1A4981;
    color: #ffffff;
    padding: 0px 5px;
    border-radius: 5px;
    line-height: 25px;
    display: none;
    top: 35px;
    width: 100px;
}
.addToQueue:hover > .hoverHolder{
    display: block;
}  
.addToQueue2:hover > .hoverHolder2{
    display: block;
}   
.dashSplit{
    width:50%;
    display: inline-block;
    vertical-align: top;
}
.blockSelector{
    display: inline-block;
    width: 90px;
    margin: 0px 5px;
    line-height: 25px;
    border-radius: 5px;
    font-weight: 600;
    cursor:pointer;
    transition: all .5s;
}
.selected{
    background-color: var(--quility-color) !important;
    color: var(--quility-light-text);
    border-radius: 5px;
}
.removeFilterElement{
    background: #1A4981 0% 0% no-repeat padding-box;
    border: 4px solid #426896;
    border-radius: 6px;
    opacity: 1;
    color: #ffffff;
    line-height: 15px;
    width: -moz-fit-content;
    width: fit-content;
    height: 15px;
    text-align: center;
    font-size: 12px;
    min-width: 15px;
    position: absolute;
    top: -10px;
    right: -10px;
    cursor: pointer;
    font-weight: 900;
    visibility: hidden;
    transition: all .25s;
}
.hoverHolder2{
    position: absolute;
    font-size: 14px;
    margin-left: -35px;
    margin-top: 0px;
    background-color: #1A4981;
    color: #ffffff;
    padding: 0px 5px;
    border-radius: 5px;
    line-height: 25px;
    display: none;
    right: -105px; 
    top: 5px;
}
.filterList{
    margin: 5px 0px;
}
.emptyQueue{
    width: 95%;
    height: 300px;
    border: dashed 1px;
    margin: auto;
    border-radius: 5px;
    position: relative;
    transition: all .5s;
    background-color: #ffffff;
}
.timeOptions{
    display: flex;
    justify-content: space-evenly;
    padding: 10px;
    font-weight: 700;
}
.marketLine{
    display: inline-block;
    flex: 1 1;
    max-width: 23%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.filterOuterWrapper{
    display: flex;
    flex-wrap: nowrap;
    margin: 15px;
    height: 400px;
    position: relative;
}
.mainFilterBlockWrapper{
    overflow: hidden;
    transition: all .5s;
    background-color: var(--quility-nested-block-background-color);
    width: -moz-fit-content;
    height: 100%;
    margin: 0px 0px 0px 0px;
    width: 0px;
    border: unset;
    border-radius: 5px;
}
.addToQueueButton{
    border: solid 1px #45bcaa;
    padding: 2px 10px;
    border-radius: 5px;
    color: white;
    background-color: #45bcaa;
    font-weight: 700;
}
/*.expandFilters{
    width: 260px;
    padding: 0px 20px 0px 0px;
    border-right: solid 0.5px;
    overflow:auto;
}*/
.expandFilters{
    width: calc(100% - 40px);
    margin: auto;
    overflow: auto;
    padding-bottom: 10px;
}
.bulkTitle{
    line-height: 36px;
    font-weight: 700;
    text-align: left;
    margin: 0px 10px;
    font-size: 16px;
    padding: 5px 0px;
    color: var(--quility-dark-text);
}
.dialSmartListButton{
    height: 40px;
    line-height: 40px;
    border-radius: 3px;
    margin-bottom: 10px;
    cursor: pointer;
}
.selectedHolder{
    text-align: left;
}
.clearFilters{
    display: inline-block;
    font-size: 12px;
    padding: 4px 4px;
    margin: 0px 10px;
    text-indent: 0;
    background-color: var(--quility-button-color-destructive);
    color: var(--quility-light-text);
    width: -moz-fit-content;
    width: fit-content;
    font-weight: 700;
    cursor: pointer;
    border-radius: 10px;
    line-height: normal;
    margin-top: 6px;
}
.clearFilters:hover{
    background-color: var(--quility-button-color-destructive-hover);
}