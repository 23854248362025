#blockWrapper{
    height: 40px;
    flex: 1 1;
    text-align: center;
    line-height: 40px;
    color: var(--quility-light-text);
    cursor: pointer;
    transition: all .5s;
    overflow: hidden;
    background-color: var(--quility-color);
    text-align: left;
}
#blockWrapper:hover{
    background-color:var(--quility-dark-green);
}
.blockIcon{
    position: absolute;
    width: 40px;
}
.blockTitle{
    font-size: 13px;
    font-weight: 700;
    
}
.optionsHolder{
    background-color: #c5d9f0;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    height:0px;
}
.menuOption{
    transition: all .5s;
    padding-left: 40px;
    font-size: 13px;
    line-height: 30px;
}
.menuOption:hover{
    background-color:var(--quility-dark-green);
    color:var(--quility-light-text)
}