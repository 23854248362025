.softPhoneWrapper{
    background-color: #fbfbfb;
    border: 1px solid #c5d9f0;
    border-radius: var(--quility-border-radius);
    box-shadow: 3px 3px 25px -5px;
    color: var(--quility-dark-text);
    height: 240px;
    left: 60px;
    position: fixed;
    top: 60px;
    -webkit-user-select: none;
    user-select: none;
    width: 500px;
    z-index: 5;
}
.VoiceDropTitle{
    line-height: normal;
    color: var(--quility-dark-text);
    padding: 5px;
    font-weight: 500;

}
.noDrop{
    line-height: 100px;
    font-size: 14px;
    color: var(--quility-dark-text);
}
.dropItem{
    color: var(--quility-dark-text);
    text-align: left;
    font-size: 14px;
    text-indent: 10px;
    line-height: normal;
    padding: 10px 0px;
    cursor: pointer;
    border-bottom: solid 1px var(--quility-border-color);
}
.dropItem:hover{
    background-color: var(--quility-hover-grey-color);
}
.dropWrapper{
    background-color: var(--quility-block-background-color);
    border: 1px solid var(--quility-border-color);
    border-radius: var(--quility-border-radius);
    max-height: 300px;
    position: absolute;
    width: 87%;
    z-index: 2;
    box-shadow: 10px 20px 31px -18px #003e3a;
}
.activePhoneButtonQuility{
    background-color: var(--quility-color) !important;
    border-color: var(--quility-color) !important;
    color: var(--quility-light-text) !important;
}
.viewLeadPhone{
    cursor: pointer;
    background-color: var(--quility-color);
    padding: 5px;
    border-radius: 5px;
    color: var(--quility-light-text);
    font-weight: 600;
}
.softPhoneButtonStyle{
    border: solid 1px #c5d9f0;
    background-color: #e4effc;
}
.callWith{
    width: 200px;
    display: inline-block;
}
.wrapperTopBar{
    background-color: var(--quility-medium-dark-green);
    height: 35px;
    border-top-right-radius: var(--quility-border-radius);
    border-top-left-radius: var(--quility-border-radius);
}
.wrapperTopBarTitle{
    font-size: 15px;
    line-height: 35px;
    text-align: left;
    text-indent: 20px;
    color: var(--quility-light-text);
    font-weight: 700;
}
.lineTitle{
    display: inline-block;
    font-weight: 700;
    width: 120px;
}
.softPhoneLine{
    font-size: 13px;
    border-bottom: solid 1px #f1f1f1;
    padding: 10px 10px;
}
.softPhoneButtonWrapper{
    display: flex;
    height: 50px;
    line-height: 50px;
    text-align: center;
    justify-content: center;
}
.callStatus{
    display: inline-block;
}
.softPhoneButton{
    width: 100px;
    display: inline-block;
    height: 30px;
    line-height: 30px;
    border-radius: 5px;
    cursor: pointer;
    margin: 0px 10px;
    font-size: .9em;
    vertical-align: middle;
}
.reject{
    border: solid 1px #9e0000;
    background-color:#ffbdbd;
}
.answer{
    border: solid 1px #00a800;
    background-color:#bbffbb
}
.buttonIcon{
    margin-left: 5px;
    vertical-align: top;
}
.activePhoneButton{
    background-color:#57a4ff;
}
.staticPhoneWrapper{
    width: 90%;
    height: 0px;
    background-color: #fbfbfb;
    border-radius: 5px;
    -webkit-user-select: none;
    user-select: none;
    margin: 10px auto 0px;
    transition: all .5s;
    overflow: hidden;
}
.openPhone{
    border: solid 1px var(--quility-extra-dark-green);
    height: 215px;
    margin-bottom: 5px;
    background-color: var(--quility-extra-dark-green);
}
.keypadHolder{
    position: absolute;
    z-index: 2;
    width: 250px;
    height: 350px;
    border-radius: 5px;
    background-color: var(--quility-block-background-color);
    border: solid 1px var(--quility-dark-grey);
    box-shadow: 3px 3px 24px -3px var(--quility-border-color);
}
.keypadHeader{
    line-height: 40px;
    font-weight: 700;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    color: black;
}
.keypadNumber{
    flex: 0 0 33%;
    display: inline-block;
    margin: 5px 0px;
}
.innerKeypad{
    width: 60px;
    display: inline-block;
    height: 60px;
    border-radius: 50%;
    background: 0% 0% no-repeat padding-box;
    color: var(--quility-color);
    font-weight: 700;
    line-height: 60px;
    cursor: pointer;
    border: solid 2px var(--quility-color);
}
.keypadContent{
    display: flex;
    flex-wrap: wrap;
    background-color: var(--quility-block-background-color);
}
.formButtonWrapper{
    text-align: center;
    padding: 15px;
}