
.signupLogo{
    max-height: 220px;
    text-align: center;
    margin: auto;
    width: 80%;
    max-width: 500px;
    padding: 20px;
}
.shorten{
    width: 80%;
}
.signUpWrapper{
    width: 70%;
    margin: auto;
    text-align: center;
    max-width: 900px;
}
.completeSignUpWrapper{
    background-color: var(--quility-color);
    height: fit-content;
    min-height: 100%;
}
.searchMe{
    position: absolute;
    display: inline-block;
    height: 30px;
    border-radius: 5px;
    line-height: 30px;
    padding: 0px 10px;
    margin-left: 30px;
    cursor: pointer;
    user-select: none;
}
.existingAgent{
    width: 100%;
    text-align: center;
    color: #b00000;
    font-size: 20px;
    font-weight: 700;
    display: inline-block;
}
.goToLogin{
    width: fit-content;
    padding: 0px 10px;
    line-height: 35px;
    margin: auto;
    border-radius: 5px;
    user-select: none;
    margin-top: 20px;
}
.noExist{
    display: block;
    color: #bf0000;
}
.submitSubscription{
    display: inline-block;
    border-radius: 5px;
    line-height: 40px;
    padding: 0px 10px;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
}