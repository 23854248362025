.activityTitle{
    height: 30px;
    line-height: 30px;
    font-weight: 700;
    font-size: 18px;
}
.dataPoint{
    margin-left:20px;
    height: 30px;
}
.progressBar{
    background-color: #00357f;
    height: 100%;
}
.hiddenInfo{
    overflow: hidden;
    background-color: #c5d9f0;
    border-radius: 15px;
    width: 90%;
    margin-left: 20px;
}
.hiddenInfoWrapper{
    margin: 20px 0px;
    cursor:pointer;
}
.progressBarWrapper{
    width: 60%;
    height: 30px;
    border-radius: 5px;
    margin-left: 20px;
    border: solid 1px;
    position: relative;
    margin-bottom: 20px;
}
.overText{
    position: absolute;
    width: 50%;
    text-align: center;
    line-height: 24px;
    background-color: #e4effcb3;
    margin-left: 25%;
    height: 24px;
    margin-top: 3px;
    border-radius: 5px;
}
.progressTitle{
    width: 60%;
    text-align: center;
    margin-left: 18px;
    margin-bottom: 5px;
    font-size: 20px;
    font-weight: 700;
}
.activityBox{
    padding: 10px 10px;
}
.pickerWrapper{
    margin-left: 40px;
    width: fit-content;
}
.subSummary{
    display: inline-block;
    width: fit-content;
    font-size: 14px;
}
.pickerTitle{
    color: var(--quility-dark-text);
}
.activityDatePickerHolder{
    display: inline-block;
    margin: 10px;
    font-weight: 700;
}