.callWithItem{
    display: inline-block;
    font-size: 12px;
}
.callItemWrapper:hover > .reveal{
    display:block;
}
.reveal{
    display:none;
    position:relative;
}
.directionIndication{
    display: inline-block;
    margin: 0px 5px 0px 0px;
}
.subLineWrapper{
    margin: 5px 0px;
}
.inbound{
    transform: rotate(135deg);
    color: #2353a8;
}
.unheard{
    background-color: #f1f3f4;
}
.outbound{
    transform: rotate(-45deg);
    color: #009300;
}
.missed{
    color: #F44336;
}
.outgoingIcon{
    transform: rotateZ(-45deg);
    margin-right:5px;
}
.callLogBlock{
    border-bottom: solid 1px var(--quility-border-color);
    padding: 10px 0px;
    font-size: 14px;
    color: var(--quility-dark-text);
    margin-left: 15px;
    padding: 10px 0px;
}
.callItemWrapper{
    cursor:pointer;
}
.callItemWrapper:hover{
    background-color:var(--quility-hover-grey-color);
}