.sortWrapper{
    display: flex;
    border-bottom: solid 1px #d0d0d0;
    font-size: 14px;
    color: var(--quility-dark-text);
    line-height: 40px;
    background-color: var(--quility-nested-block-header-background-color);
    margin: 0px -10px;
    padding: 0px 10px;
}
.sortBlock{
    flex: 1;
    text-align: left;
    text-indent: 0px;
    position: relative;
}
.leadName{
    font-size: 28px;
    font-weight: 700;
    letter-spacing: 0.5px;
    text-align: left;
    text-indent: 20px;
    display: inline-block;
    color:var(--quility-dark-text);
}
.sortIcon{
    right: 22px;
    display: inline-block;
    position: absolute;
}
#sortBlockName{
    text-indent: 20px;
    flex:1.5 !important;
}
#sortBlockState{
    flex:.5;
}
#sortBlockContacted{
    flex:1.5;
}
.leadListAmountContacted{
    flex:.5 !important;
}
#leadBlockName{
    flex:1.5 !important;
    text-indent: 20px;
}
#sortBlockCalls{
    flex:.5 !important;
}
#leadBlockState{
    flex:.5 !important;
}
#leadBlockContacted{
    flex:1.5 !important;
}
.innerLeadWrapper{
    max-height: 395px;
    overflow: auto;
    display: inline-block;
    width: 100%;
    height: 90%;
}
.leadItem {
    flex: 1 1;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-indent: 15px;
    font-size: 14px;
}
.individualLeadWrapper{
    display: flex;
    height: 50px;
    line-height: 50px;
    cursor: pointer;
    border-bottom: solid 1px #f1f1f1;
    position: relative;
    color: var(--quility-dark-text);
}
.individualLeadWrapper:hover{
    background-color: var(--quility-hover-grey-color);
}
.pages{
    text-align: center;
    margin-top: 15px;
}
.page{
    display: inline-block;
    margin: 10px 5px;
    color: #ffffff;
    font-size: 22px;
    cursor:pointer;
    user-select: none;
}
.current{
    font-weight: 700;
    color: #FF8557;
    -webkit-text-stroke: 0.5px #00000063;
}
.ellipsis{
    display: inline-block;
    color: #ffffff;
    font-size: 24px;
}
.checkBoxWrapper{
    position: absolute;
    margin-left: -4px;
    display:none;
}
.leadWrapper{
    width: 95%;
    margin: auto;
    background-color: var(--quility-nested-block-background-color);
    height: 100%;
    padding: 0px 10px;
    border-radius: 3px;
    border: solid 1px var(--quility-border-color);;
}